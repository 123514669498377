import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import {
  bikeByNameReducer,
  productBySubCategoryReducer,
  allAccessoriesReducer,
  allBikesReducer,
} from "./Reducers/bikeReducers";
import {
  cartReducer,
  confirmCartItemsReducer,
  checkPromocodeReducer,
} from "./Reducers/cartReducers";
import {
  userLoginReducer,
  getUserInfoReducer,
  getUserOrdersReducer,
} from "./Reducers/userReducers";
import {
  orderCreateReducer,
  orderConfirmReducer,
  orderByIdReducer,
} from "./Reducers/orderReducers";
import { allDealersReducer } from "./Reducers/dealerReducers";
import { bookTestrideReducer } from "./Reducers/testrideReducers";
import {
  contactUsReducer,
  EMIReducer,
  partnerWithUsReducer,
  warrantyReducer,
  insuranceReducer,
} from "./Reducers/formReducers";
import { preBookReducer } from "./Reducers/preBookReducer";
import { getStoresReducer } from "./Reducers/storeReducer";
import { productByNameReducer } from "./Reducers/productReducer";

const reducer = combineReducers({
  bikeByName: bikeByNameReducer,
  cart: cartReducer,
  preBook: preBookReducer,
  confirmCartItems: confirmCartItemsReducer,
  userLogin: userLoginReducer,
  getUserInfo: getUserInfoReducer,
  orderCreate: orderCreateReducer,
  orderConfirm: orderConfirmReducer,
  allDealers: allDealersReducer,
  bookTestride: bookTestrideReducer,
  contactUs: contactUsReducer,
  EMI: EMIReducer,
  partnerWithUs: partnerWithUsReducer,
  warranty: warrantyReducer,
  insurance: insuranceReducer,
  getUserOrders: getUserOrdersReducer,
  orderById: orderByIdReducer,
  checkPromocode: checkPromocodeReducer,
  productBySubCategory: productBySubCategoryReducer,
  allAccessories: allAccessoriesReducer,
  allBikes: allBikesReducer,
  getStores: getStoresReducer,
  productByName: productByNameReducer,
});

// const cartItemsFromStorage = localStorage.getItem(
// 	"cartItems",
// )
// 	? JSON.parse(localStorage.getItem("cartItems"))
// 	: []

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : [];

const initialState = {
  cart: {
    cartItems: [],
  },
  userLogin: { loginInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,

  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
