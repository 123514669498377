import React, { useEffect, useState } from "react";
import "../CSS/SurveyForm.css"; // Import the CSS file for styling
import bluelogo from "../images/EM-LOGO_Blue.png";
import axios from "axios";

const SurveyForm = () => {
  const [rating, setRating] = useState(null);
  const [feedbackText, setFeedbackText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [id, setId] = useState("");

  const handleRatingChange = async (event) => {
    const value = parseInt(event.target.value);
    setRating(value);
    setSubmitted(false); // Reset submitted state when rating changes
  };

  useEffect(() => {
    if (!id && rating !== null) {
      const submitNPSRating = async () => {
        const npsRating = { rating };
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        try {
          const result = await axios.post("/api/form/nps", npsRating, config);
          if (result) {
            setId(result.data.feedback._id);
          }
        } catch (error) {
          console.error("Error submitting feedback:", error);
        }
      };

      submitNPSRating();
    }
  }, [id, rating]);

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
    setSubmitted(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const result = await axios.put(
        `/api/form/nps/${id}`,
        { rating, feedback: feedbackText },
        config
      );
      alert("Thank you for your valuable feedback!");
      window.location.href = "https://emotorad.com";
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <div className="survey-screen">
      <div className="w-100 logo-bar">
        <div className=" desktop-nav m-0 p-0 p-3 ps-lg-5 pe-lg-5 ">
          <div className="d-flex justify-content-center ">
            <img
              src={bluelogo}
              className="img-fluid"
              style={{ width: "170px" }}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="survey-component">
        <form onSubmit={handleSubmit}>
          <div className="survey-form px-3 py-3 px-lg-4 py-lg-4">
            <h4 className="rating">
              How likely are you to recommend us to a friend or colleague?
            </h4>
            <div className="rating-input">
              {[...Array(11).keys()].map((num) => (
                <label key={num}>
                  <input
                    required
                    type="radio"
                    name="rating"
                    value={num}
                    checked={rating === num}
                    onChange={handleRatingChange}
                  />
                  <span>{num}</span>
                </label>
              ))}
            </div>

            <div
              className={`feedback-text my-3 ${rating !== null ? "show" : ""}`}
            >
              {rating !== null && (
                <>
                  {rating >= 9 && (
                    <div className="feedback-wow">
                      <p>WOW - Thanks for your positive feedback!</p>
                      <p>
                        We’re happy to hear you’re so pleased and hope to
                        continue meeting your expectations for a great customer
                        experience with us. Before you go, can we please ask -
                        What did we do so well?
                      </p>
                      <textarea
                        value={feedbackText}
                        onChange={handleFeedbackChange}
                        placeholder="Enter your feedback..."
                      />
                    </div>
                  )}

                  {rating >= 7 && rating <= 8 && (
                    <div className="feedback-improve">
                      <p>
                        Many thanks indeed for taking the time to give us your
                        feedback.
                      </p>
                      <p>
                        Our goal is to make our customers as happy as possible.
                        To help us pinpoint the extra improvements that would
                        make a difference to you, can you please tell us - what
                        extra could we have done to make your score a 10?
                      </p>
                      <textarea
                        value={feedbackText}
                        onChange={handleFeedbackChange}
                        placeholder="Enter your feedback..."
                      />
                    </div>
                  )}

                  {rating >= 0 && rating <= 6 && (
                    <div className="feedback-improve">
                      <p>Thank you so much for your feedback.</p>
                      <p>
                        We’re committed to listening to and learning from all
                        our customers’ experiences, positive or critical. We’d
                        like to apologize if we’ve fallen short of your
                        expectations and invite you to elaborate further so that
                        we can address any specific areas of improvements.
                      </p>
                      <p>
                        Can we please ask, which of the following do we need to
                        improve on?
                      </p>
                      <textarea
                        value={feedbackText}
                        onChange={handleFeedbackChange}
                        placeholder="Enter your feedback..."
                      />
                    </div>
                  )}

                  <button type="submit" className="submit-button">
                    Complete
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SurveyForm;
