import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../Components/Navbar";
import "@splidejs/react-splide/css";

import { useSelector, useDispatch } from "react-redux";
import "../CSS/cart.css";
import { getAllAccessories } from "../Actions/bikeActions";
import {
  Link,
  useNavigate,
  // useParams,
} from "react-router-dom";
import {
  addToCart,
  removeFromCart,
  getPromocodes,
  resetCart,
} from "../Actions/cartActions";
import ReactGA from "react-ga";
import QueryString from "qs";
import Meta from "../Components/Meta";
import { getloginUserInfo } from "../Actions/userAction";
// import { CONFIRM_CART_ITEMS } from "../Constants/cartConstants"
import Footer from "../Components/Footer";
import { ORDER_CREATE_RESET } from "../Constants/orderConstants";
import { ORDER_CONFIRM_SUCCESS } from "../Constants/orderConstants";
import { getOrderDetailsById } from "../Actions/orderActions";
import payuLogo from "../images/PayU_logo.png";
import razorpayLogo from "../images/Razorpay_logo.png";
import logo from "../images/logo1.png";

const CartScreen = () => {
  const [gstChecker, setGstChecker] = useState(false);

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const userLogin = useSelector((state) => state.userLogin);
  const { loginInfo } = userLogin;

  const getUserInfo = useSelector((state) => state.getUserInfo);
  // const { userInfo: userDetails } = getUserInfo

  const allAccessories = useSelector((state) => state.allAccessories);
  const { accessories } = allAccessories;

  const checkPromocode = useSelector((state) => state.checkPromocode);
  const { promocodes } = checkPromocode;

  const [promocode, setPromocode] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const orderCreate = useSelector((state) => state.orderCreate);
  const { orderResult, error } = orderCreate;
  const [model, setModel] = useState(0);

  useEffect(() => {
    let cartItemsFromStorage = localStorage.getItem("cartItems");
    cartItemsFromStorage = JSON.parse(cartItemsFromStorage);

    if (cartItemsFromStorage && cartItemsFromStorage.length > 0) {
      const newItems = cartItemsFromStorage.filter(
        (itemFromStorage) =>
          !cartItems.some(
            (itemInStore) =>
              itemInStore.product === itemFromStorage.product &&
              itemInStore.model === itemFromStorage.model
          )
      );

      newItems.forEach((item) => {
        dispatch(
          addToCart(
            item.product,
            item.model,
            item.color,
            item.qty,
            item.variant
          )
        );
      });
    }
  }, []);

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const checkoutid = params.get("checkoutid");

    if (checkoutid) {
      dispatch(getOrderDetailsById(checkoutid));
    }
  }, []);

  useEffect(() => {
    if (orderResult) {
      setName(orderResult.name);
      setEmail(orderResult.email);
      setMobileNumber(orderResult.phoneNumber);
      setPincode(orderResult.address.pincode);
      setAddressLine1(orderResult.address.addressLine1);
      setCity(orderResult.address.city);
      setState(orderResult.address.state);

      for (let index = 0; index < orderResult.items.length; index++) {
        dispatch(
          addToCart(
            orderResult.items[index].product,
            orderResult.items[index].model,
            orderResult.items[index].color,
            orderResult.items[index].qty,
            orderResult.items[index].variant
          )
        );
      }
    }
  }, [orderResult]);

  useEffect(() => {
    dispatch({ type: ORDER_CREATE_RESET });
  }, []);

  useEffect(() => {
    const urlParams = document.URL.split("?");

    const values = QueryString.parse(urlParams[1]);
    if (values.id) {
      if (values.id.constructor === Array) {
        for (let index = 0; index < values.id.length; index++) {
          dispatch(
            addToCart(
              values.id[index],
              values.model[index],
              values.color[index],
              1
            )
          );
        }
      } else {
        dispatch(
          addToCart(
            values.id,
            parseInt(values.model),
            parseInt(values.color),
            1
          )
        );
      }
    }
  }, []);

  useEffect(() => {
    dispatch(getAllAccessories());
  }, []);

  const currencyFormat = (num) => {
    return "₹" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  useEffect(() => {
    if (loginInfo) {
      dispatch(getloginUserInfo(loginInfo._id));
    }
  }, []);

  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });

  const userDetailsFromLocalStorage =
    localStorage.getItem("userDetails") &&
    JSON.parse(localStorage.getItem("userDetails"));

  const [name, setName] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.name : ""
  );
  const [email, setEmail] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.email : ""
  );
  const [mobileNumber, setMobileNumber] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.mobileNumber : ""
  );
  const [state, setState] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.state : ""
  );
  const [city, setCity] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.city : ""
  );
  const [pincode, setPincode] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.pincode : ""
  );
  const [addressLine1, setAddressLine1] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.addressLine1 : ""
  );
  const [addressLine2, setAddressLine2] = useState(
    userDetailsFromLocalStorage ? userDetailsFromLocalStorage.addressLine2 : ""
  );

  const [paymentMethod, setPaymentMethod] = useState("Razorpay");

  const [gstin, setGSTIN] = useState("");

  // check promocode
  useEffect(() => {
    dispatch(getPromocodes(promocode));
  }, [promocode]);

  // add discount to products
  // if (promocodes) {
  //   if (promocodes.length > 0 && promocodes[0].type == "flat") {
  //     for (let index = 0; index < cartItems.length; index++) {
  //       if (promocodes[0].for.find((i) => i === cartItems[index].product)) {
  //         let newItem = cartItems[index];

  //         newItem.price = Number(
  //           cartItems[index].accPrice - promocodes[0].value
  //         );
  //       }
  //     }
  //   }
  // } else {
  //   for (let index = 0; index < cartItems.length; index++) {
  //     let newItem = cartItems[index];

  //     newItem.price = Number(cartItems[index].accPrice);
  //   }
  // }

  // check variant
  // if (cartItems) {
  //   for (let index = 0; index < cartItems.length; index++) {
  //     if (cartItems[index]?.variant?.variantType) {
  //       let newItem = cartItems[index];

  //       newItem.price = Number(
  //         cartItems[index].variant[cartItems[index].variantType].price
  //       );

  //       newItem.accPrice = Number(
  //         cartItems[index].variant[cartItems[index].variantType].price
  //       );
  //     }
  //   }
  // }
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  useEffect(() => {
    if (pincode.length >= 6) {
      getAddressDetails();
    }
  }, [pincode]);

  // get ctiy and State from post number
  const getAddressDetails = async () => {
    const options = {
      method: "POST",
      url: "https://pincode.p.rapidapi.com/",
      headers: {
        "content-type": "application/json",
        "Content-Type": "application/json",
        "X-RapidAPI-Key": "04b797c9ecmsh5c4ceefb0937d50p162359jsn6885c5864bf7",
        "X-RapidAPI-Host": "pincode.p.rapidapi.com",
      },
      data: {
        searchBy: "pincode",
        value: pincode,
      },
    };

    try {
      const { data } = await axios.request(options);
      if (data.length > 0) {
        setCity(data[0].district && data[0].district);
        setState(data[0].circle && data[0].circle);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const analyticEvent = (category, action) => {
    ReactGA.event({
      category: category,
      action: action,
    });
  };
  const displayRazorpay = async (e) => {
    e.preventDefault();
    analyticEvent("Place Order", "Place Order Clicked");
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    localStorage.setItem(
      "userDetails",
      JSON.stringify({
        name,
        email,
        mobileNumber,
        state,
        city,
        pincode,
        addressLine1,
        addressLine2,
      })
    );

    const result = await axios.post(
      "/api/order/create",

      {
        name,
        email,
        phoneNumber: mobileNumber,
        address: {
          addressLine1,
          addressLine2,
          city,
          state,
          pincode,
        },
        items: cartItems.map((item) => {
          const { price, accPrice, ...rest } = item;
          return rest;
        }),
        promoCode: promocode && promocode,
        gstin,
        paymentMethod,
      },

      config
    );
    if (!result) {
      alert("Server error. Are you online?");
      return;
    }
    const { order, orderDetails } = result.data;
    if (order.amount) {
      if (paymentMethod === "Razorpay") {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
          amount: order.amount.toString(),
          currency: order.currency,
          name: "EMotorad",

          image: logo,
          order_id: order.id,
          handler: async function (response) {
            const data = {
              orderCreationId: order.id,
              razorpayPaymentId: response.razorpay_payment_id,
              razorpayOrderId: response.razorpay_order_id,
              razorpaySignature: response.razorpay_signature,
              orderId: orderDetails._id,
            };

            const result = await axios.post(
              "/api/payment/success",
              data,
              config
            );

            if (result) {
              dispatch({
                type: ORDER_CONFIRM_SUCCESS,
              });
              navigate("/thank-you");
            } else {
              navigate("/payment-failed");
            }
          },
          prefill: {
            name: orderDetails.name,
            email: orderDetails.email,
            contact: orderDetails.phoneNumber,
          },
        };

        const paymentObject = new window.Razorpay(options);
        const paydata = await paymentObject.open(options);
      } else {
        const PAYU_KEY = "GLkzB8";
        const productinfo =
          orderDetails.items.length > 1
            ? orderDetails.items
                .slice(0, -1)
                .map((item) => item.name)
                .join(", ") +
              " and " +
              orderDetails.items[orderDetails.items.length - 1].name
            : orderDetails.items.map((item) => item.name).join(", ");
        const form = document.createElement("form");
        form.method = "post";
        form.action = "https://secure.payu.in/_payment";

        const formData = {
          key: PAYU_KEY,
          txnid: orderDetails._id,
          productinfo: productinfo,
          amount: orderDetails.amount,
          email: orderDetails.email,
          firstname: orderDetails.name,
          surl: `https://www.emotorad.com/api/payment/payu-success?txnid=${orderDetails._id}`,
          furl: "https://www.emotorad.com/api/payment/payu-failure",
          phone: orderDetails.phoneNumber,
          hash: order.hash,
        };

        Object.keys(formData).forEach((key) => {
          const hiddenField = document.createElement("input");
          hiddenField.type = "hidden";
          hiddenField.name = key;
          hiddenField.value = formData[key];
          form.appendChild(hiddenField);
        });

        document.body.appendChild(form);

        form.submit();
      }
    } else {
      const data = { promocode: "nullify", orderId: orderDetails._id };
      const result = await axios.post("/api/payment/success", data, config);
      if (result) {
        dispatch({
          type: ORDER_CONFIRM_SUCCESS,
        });
        navigate("/thank-you");
      } else {
        console.log("some error occured");
      }
    }
  };

  return (
    <>
      <Meta
        title={" EMotorad | Cart"}
        description={
          "EMotorad offers high tech, reliable, and fast electric cycles with premium quality. Now book e bicycle & e bike online with EMotorad at best price."
        }
      />
      <Navbar />
      {cartItems.length > 0 ? (
        <div className="container mt-lg-5 mt-lg-0">
          {/* ..............Accessories list ................ */}
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-scrollable  modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title " id="exampleModalLabel">
                    Accessories
                  </h6>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {accessories &&
                    accessories
                      .filter((i) => i.name !== "Beacon")
                      .map((accItem, i) => (
                        <div key={i}>
                          <div
                            key={i}
                            className="cart-item-sm p-lg-2 pt-4 pb-2 row align-items-center "
                            style={{
                              borderRadius: "5px",
                            }}
                          >
                            <div className=" col-4 overflow-hidden p-0">
                              <img
                                src={accItem.cartImages[0]}
                                alt=""
                                className=" img-fluid  "
                              />
                            </div>
                            <div className="col-5  p-0 ps-3">
                              <h6
                                className="mb-1"
                                style={{
                                  fontWeight: "700",
                                  fontSize: "14px",
                                }}
                              >
                                {accItem.name}
                              </h6>
                              <p
                                className="mb-2"
                                style={{
                                  fontSize: "0.7em",
                                  color: "grey",
                                }}
                              >
                                ({accItem.productType})
                              </p>
                              <h6
                                className="mb-0"
                                style={{
                                  fontWeight: "700",
                                  fontSize: "0.8em",
                                  color: "#3CAB1F",
                                }}
                              >
                                MRP: {currencyFormat(accItem.accPrice)}
                              </h6>
                            </div>
                            {accItem.models.length}

                            <div className="col-3 ">
                              {cartItems.find(
                                (finder) => finder.product === accItem._id
                              ) ? (
                                <button
                                  type="button"
                                  className="bg-primary d-block mx-auto"
                                  style={{
                                    outline: "0px",
                                    borderRadius: "50%",

                                    width: "40px",
                                    height: "40px",
                                    border: "0px",
                                  }}
                                >
                                  <i className="fa-solid  text-light fa-check"></i>
                                </button>
                              ) : accItem.inStock > 0 ? (
                                <button
                                  className=" d-block mx-auto"
                                  onClick={() =>
                                    dispatch(addToCart(accItem._id, 0, 1, 1))
                                  }
                                  style={{
                                    outline: "0px",
                                    borderRadius: "50%",

                                    width: "40px",
                                    height: "40px",
                                    border: "0px",
                                    background: "#10b068",
                                  }}
                                >
                                  <i className="fa-solid text-light fa-plus"></i>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="bg-muted d-block mx-auto"
                                  style={{
                                    outline: "0px",
                                    borderRadius: "50%",

                                    width: "40px",
                                    height: "40px",
                                    border: "0px",
                                  }}
                                >
                                  <i className="fa-solid fa-exclamation"></i>
                                </button>
                              )}
                            </div>
                          </div>
                          <hr
                            className="mb-0 mt-2"
                            style={{
                              color: "grey",
                            }}
                          />
                        </div>
                      ))}
                </div>
              </div>
            </div>
          </div>

          <form onSubmit={displayRazorpay}>
            {/* ........................................ */}
            <div className="row">
              <div className="col-lg-6 pe-lg-5  p-1">
                <h6 className=" ps-2 mt-3  d-lg-none cart-heading">
                  YOUR SELECTIONS
                </h6>
                <div className="cart-items d-lg-none  pt-0 p-3">
                  {cartItems.map((item, i) => (
                    <div key={i}>
                      <div
                        className="cart-item-sm p-lg-2 pt-4 pb-2 row "
                        style={{
                          borderRadius: "5px",
                        }}
                      >
                        <div className="col-lg-3 col-4 overflow-hidden p-0">
                          <img
                            src={item.cartImages[item.model]}
                            alt=""
                            className=" img-fluid  "
                          />
                        </div>
                        <div className="col-4 pt-2 p-0 ps-3">
                          <h6
                            className="mb-1"
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            {item.name}
                          </h6>
                          {item.variants && (
                            <p
                              className="mb-2"
                              style={{
                                fontSize: "0.7em",
                                color: "grey",
                              }}
                            >
                              {item.variants[item.variant].variantName}
                            </p>
                          )}
                          <p
                            className="mb-2"
                            style={{
                              fontSize: "0.7em",
                              color: "grey",
                            }}
                          >
                            {item.models[item.model].colorName}
                          </p>
                          <h6
                            className="mb-0"
                            style={{
                              fontWeight: "700",
                              fontSize: "0.8em",
                              color: "#3CAB1F",
                            }}
                          >
                            MRP: {currencyFormat(item.accPrice)}
                          </h6>
                        </div>
                        <div className="col-4 ">
                          <div className="d-flex">
                            <div
                              className="col-4"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                onClick={(e) => {
                                  dispatch(
                                    removeFromCart(
                                      item.productModel,
                                      item.colorName,
                                      item.name,
                                      item.variantType
                                    )
                                  );
                                }}
                                className="fa-solid fa-minus"
                              ></i>
                            </div>
                            <p className="col-4 text-center">{item.qty}</p>
                            <div
                              className="col-4 text-end"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                onClick={(e) =>
                                  dispatch(
                                    addToCart(
                                      item.product,
                                      item.model,
                                      item.color,
                                      Number(item.qty + 1),
                                      item.variant
                                    )
                                  )
                                }
                                className="fa-solid fa-plus"
                              ></i>
                            </div>
                          </div>

                          <i
                            style={{
                              color: "#b8baba",
                              fontSize: "0.8em",
                            }}
                            onClick={() =>
                              dispatch(
                                resetCart(
                                  item.productModel,
                                  item.colorName,
                                  item.name,
                                  item.variantType
                                )
                              )
                            }
                            className="fa-sharp mt-3 text-end d-block fa-solid fa-trash"
                          ></i>
                        </div>
                      </div>
                      <hr
                        className="mb-0  mt-2"
                        style={{
                          color: "grey",
                        }}
                      />
                    </div>
                  ))}
                </div>

                <div className="d-flex p-2 d-lg-none">
                  <p
                    className="col-8"
                    style={{
                      color: "grey",
                      fontSize: "0.7em",
                    }}
                  >
                    Personalize your ride with our wide selection of bicycle
                    accessories
                  </p>
                  <div className=" col-4">
                    <button
                      type="button"
                      className="btn w-100 ps-1 pe-1 pt-2 pb-2 "
                      style={{
                        background: "#cedfe0",
                        color: "grey",
                        fontSize: "0.6em",
                        outline: "0px",
                        border: "0px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="fa-solid fa-plus"></i> View Accessories
                    </button>
                  </div>
                </div>

                <div className="p-2 pb-0 mb-4 d-lg-none">
                  <div className="field-wrapper">
                    <input
                      value={promocode}
                      autoComplete="new-password"
                      className={`${promocode.length > 0 && "bg-white"}`}
                      onChange={(e) =>
                        setPromocode(e.target.value.toUpperCase().trim())
                      }
                      type="text"
                      name="promocode"
                      style={{
                        border: "2px dotted  #b1b3b6",
                      }}
                      id=""
                    />
                    <div className="field-placeholder">
                      <span>Apply Promocode</span>
                    </div>
                  </div>
                  {promocode.length > 3 ? (
                    promocodes ? (
                      <p
                        className="col-8 mt-1 text-success"
                        style={{
                          fontSize: "0.8em",
                        }}
                      >
                        Promocode Applied
                      </p>
                    ) : (
                      <p
                        className="col-8 mt-1 text-danger"
                        style={{
                          fontSize: "0.8em",
                        }}
                      >
                        Invaid Promocode
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className="p-2 pt-0 pb-0 d-lg-none">
                  <hr
                    className="mb-0 mt-2"
                    style={{
                      color: "grey",
                    }}
                  />
                </div>

                <div className="p-2 mt-3 mt-lg-2 pt-0">
                  <h6 className="cart-heading">BILLING & SHIPPING ADDRESS</h6>

                  <div>
                    <div className="row mt-lg-4 mt-3 mb-3">
                      <div className="col-lg-6 d-flex">
                        <div
                          style={{
                            width: "17%",
                            border: " 1px solid #b1b3b6",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                            borderRight: "0px solid red",
                          }}
                          className="d-flex align-items-center "
                        >
                          <img
                            src="https://cdn-icons-png.flaticon.com/128/330/330439.png"
                            alt=""
                            className="img-fluid d-block mx-auto"
                            style={{
                              width: "60%",
                            }}
                          />
                        </div>
                        <div
                          className=""
                          style={{
                            width: "83%",
                          }}
                        >
                          <div className="field-wrapper">
                            <input
                              type="number"
                              name="phone number"
                              value={mobileNumber}
                              required
                              onChange={(e) => {
                                const mobileNumber = e.target.value.slice(
                                  0,
                                  12
                                );
                                setMobileNumber(mobileNumber);

                                if (/^[6-9]\d{9}$/.test(mobileNumber)) {
                                  e.target.setCustomValidity("");
                                } else {
                                  e.target.setCustomValidity(
                                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                                  );
                                }
                              }}
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                                )
                              }
                              autoComplete="new-password"
                              className={`${
                                mobileNumber.length > 0 && "bg-white"
                              }`}
                              style={{
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                              }}
                              id=""
                            />
                            <div className="field-placeholder">
                              <span>Mobile Number*</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-0">
                        {" "}
                        <div className="field-wrapper">
                          <input
                            type="email"
                            name="email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                            className={`${email.length > 0 && "bg-white"}`}
                            autoComplete="new-password"
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div className="field-placeholder">
                            <span>Email*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div className="field-wrapper">
                          <input
                            type="text"
                            name="Full Name"
                            value={name}
                            required
                            autoComplete="new-password"
                            onChange={(e) => setName(e.target.value)}
                            className={`${name.length > 0 && "bg-white"}`}
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div className="field-placeholder">
                            <span>Full Name*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div className="field-wrapper">
                          <input
                            type="number"
                            name="pincode"
                            autoComplete="new-password"
                            required
                            value={pincode}
                            onChange={(e) =>
                              setPincode(e.target.value.slice(0, 6))
                            }
                            className={`${pincode.length > 0 && "bg-white"}`}
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div className="field-placeholder">
                            <span>Pincode*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 mt-4 mt-lg-4">
                        {" "}
                        <div className="field-wrapper">
                          <input
                            type="text"
                            name="Address"
                            required
                            autoComplete="new-password"
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                            className={`${
                              addressLine1.length > 0 && "bg-white"
                            }`}
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div className="field-placeholder">
                            <span>House Number, Landmark, Area *</span>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div className="field-wrapper">
                          <input
                            value={city}
                            required
                            autoComplete="new-password"
                            onChange={(e) => setCity(e.target.value)}
                            className={`${city.length > 0 && "bg-white"}`}
                            type="text"
                            name="city"
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div className="field-placeholder">
                            <span>City*</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-4 mt-lg-4">
                        {" "}
                        <div className="field-wrapper">
                          <select
                            name="state"
                            id=""
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          >
                            <option value="" defaultValue disabled>
                              State*
                            </option>

                            <option value="Andhra Pradesh">
                              Andhra Pradesh
                            </option>
                            <option value="Arunachal Pradesh">
                              Arunachal Pradesh
                            </option>
                            <option value="Andaman and Nicobar">
                              Andaman and Nicobar
                            </option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Delhi"> Delhi</option>
                            <option value="Daman and Diu">Daman and Diu</option>
                            <option value="Dadra">Dadra</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Pradesh">
                              Himachal Pradesh
                            </option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Jammu and Kashmir">
                              Jammu and Kashmir
                            </option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Pradesh">
                              Madhya Pradesh
                            </option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Nagar Haveli ">Nagar Haveli </option>
                            <option value="Odisha">Odisha</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                            <option value="West Bengal">West Bengal</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={gstChecker}
                        onChange={(e) => setGstChecker(e.target.checked)}
                        id="flexCheckDefault"
                      />

                      <label
                        style={{
                          fontSize: "0.8em",
                        }}
                        className="form-check-label"
                        htmlhtmlfor="flexCheckDefault"
                      >
                        Claim GST Invoice
                      </label>
                    </div>
                    {gstChecker && (
                      <div className="col-12 mb-lg-4 mt-3 mt-lg-3">
                        {" "}
                        <div className="field-wrapper">
                          <input
                            value={gstin}
                            autoComplete="new-password"
                            onChange={(e) =>
                              setGSTIN(
                                e.target.value.slice(0, 15).toUpperCase()
                              )
                            }
                            className={`${gstin.length > 0 && "bg-white"}`}
                            type="text"
                            name="city"
                            style={{
                              borderTopLeftRadius: "0px",
                              borderBottomLeftRadius: "0px",
                            }}
                            id=""
                          />
                          <div className="field-placeholder">
                            <span>GSTIN</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-lg-flex p-2 d-none">
                    <p
                      className="col-8"
                      style={{
                        color: "grey",
                        fontSize: "0.95em",
                      }}
                    >
                      Personalize your ride with our wide selection of bicycle
                      accessories
                    </p>
                    <div className=" col-4">
                      <button
                        type="button"
                        className="btn w-100 ps-1 pe-1 pt-2 pb-2 "
                        style={{
                          background: "#cedfe0",
                          color: "grey",
                          fontSize: "0.8em",
                          outline: "0px",
                          border: "0px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="fa-solid fa-plus"></i> View Accessories
                      </button>
                    </div>
                  </div>
                  <div className="cart-items d-none d-lg-block p-3">
                    {cartItems.map((item, i) => (
                      <div
                        key={i}
                        className="cart-item mb-3 p-lg-2 pt-3 pb-3 row  "
                      >
                        <div className="col-lg-3 col-4  p-0">
                          <img
                            src={item.cartImages[item.model]}
                            alt=""
                            className=" img-fluid  "
                          />
                        </div>
                        <div className="col-4 offset-1 pt-3  ">
                          <h6
                            className="mb-1"
                            style={{
                              fontWeight: "700",
                              fontSize: "20px",
                            }}
                          >
                            {item.name}{" "}
                            {item.variants && (
                              <>
                                <span>|</span>{" "}
                                <span>
                                  {item.variants[item.variant].variantName}
                                </span>
                              </>
                            )}
                          </h6>
                          <p
                            className="mb-2"
                            style={{
                              fontSize: "0.8em",
                              color: "grey",
                            }}
                          >
                            {item.models[item.model].colorName}
                          </p>
                          <h6
                            className="mb-0"
                            style={{
                              fontWeight: "700",
                              fontSize: "0.9em",
                              color: "#3CAB1F",
                            }}
                          >
                            MRP: {currencyFormat(item.accPrice)}
                          </h6>
                        </div>
                        <div className="col-3 offset-1 pt-3 position-relative ">
                          <div className="d-flex">
                            <div
                              className="col-4"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="fa-solid fa-minus"
                                onClick={(e) => {
                                  dispatch(
                                    removeFromCart(
                                      item.productModel,
                                      item.colorName,
                                      item.name,
                                      item.variantType
                                    )
                                  );
                                }}
                              ></i>
                            </div>
                            <p className="col-4 text-center">{item.qty}</p>
                            <div
                              className="col-4 text-end"
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              <i
                                className="fa-solid fa-plus"
                                onClick={(e) => {
                                  dispatch(
                                    addToCart(
                                      item.product,
                                      item.model,
                                      item.color,
                                      Number(item.qty + 1),
                                      item.variant
                                    )
                                  );
                                }}
                              ></i>
                            </div>

                            <div
                              className="position-absolute"
                              style={{
                                bottom: "0%",
                                right: "0%",
                              }}
                            ></div>
                          </div>
                          <i
                            style={{
                              color: "#b8baba",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              dispatch(
                                resetCart(
                                  item.productModel,
                                  item.colorName,
                                  item.name,
                                  item.variant
                                )
                              )
                            }
                            className="fa-sharp mt-3 text-end d-block fa-solid fa-trash"
                          ></i>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-1 d-none justify-content-center pb-5 d-lg-flex">
                <div className="border" style={{ width: "1px" }}></div>
              </div>
              <div className="col-lg-5   ps-lg-5 pe-lg-5 p-1">
                <div className="p-2 mt-3 mt-lg-2 pt-0">
                  <div className=" pb-0 mb-3 d-none d-lg-block">
                    <div className="field-wrapper">
                      <input
                        type="text"
                        value={promocode}
                        autoComplete="new-password"
                        className={`${promocode.length > 0 && "bg-white"}`}
                        onChange={(e) =>
                          setPromocode(e.target.value.toUpperCase().trim())
                        }
                        name="promocode"
                        style={{
                          border: "2px dotted  #b1b3b6",
                        }}
                        id=""
                      />
                      <div className="field-placeholder">
                        <span>Apply Promocode</span>
                      </div>
                    </div>
                    {promocode.length > 3 ? (
                      promocodes ? (
                        <p
                          className="col-8 mt-1 text-success"
                          style={{
                            fontSize: "0.8em",
                          }}
                        >
                          Promocode Applied
                        </p>
                      ) : (
                        <p
                          className="col-8 mt-1 text-danger"
                          style={{
                            fontSize: "0.8em",
                          }}
                        >
                          Invaid Promocode
                        </p>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="order-summary">
                    <h6 className="cart-heading p-lg-4 p-3 pb-lg-2 mb-0">
                      ORDER SUMMARY
                    </h6>
                    <div
                      className="position-relative  "
                      style={{ height: "20px" }}
                    >
                      <div
                        className=" w-100 position-absolute"
                        style={{
                          borderBottom: "2px  dotted #BCB5B5",
                          top: "50%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          height: "20px",
                          borderRadius: "50%",
                          width: "20px",
                          left: "-2%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          borderRadius: "50%",
                          height: "20px",
                          width: "20px",
                          right: "-2%",
                        }}
                      ></div>
                    </div>

                    <div className="mt-3 p-lg-4 p-3 pt-0 pb-lg-0 pt-lg-0">
                      <div className=" justify-content-between d-flex">
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          Items
                        </p>
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          {cartItems.reduce((acc, item) => acc + item.qty, 0)}
                        </p>
                      </div>
                      <div className=" justify-content-between d-flex">
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          Sub Total
                        </p>
                        <p
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          {currencyFormat(
                            cartItems.reduce(
                              (acc, item) => acc + item.qty * item.accPrice,
                              0
                            )
                          )}
                        </p>
                      </div>
                      <div className=" justify-content-between d-flex">
                        <p
                          className="mb-2 col-6"
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          Delivery
                        </p>
                        <p
                          className="mb-2 text-end"
                          style={{
                            fontSize: "0.9em",
                            color: "grey",
                          }}
                        >
                          <span className="col-5  text-end">
                            {Number(
                              cartItems.reduce(
                                (acc, item) => acc + item.qty * item.price,
                                0
                              )
                            ) > 1450 ? (
                              <>
                                <span
                                  className="me-2 "
                                  style={{
                                    textDecoration: "line-through",
                                  }}
                                >
                                  {currencyFormat(
                                    cartItems.reduce(
                                      (acc, item) =>
                                        acc +
                                          Number(
                                            cartItems.reduce(
                                              (acc, item) =>
                                                acc + item.qty * item.price,
                                              0
                                            )
                                          ) >
                                        5000
                                          ? 2000
                                          : 200,
                                      0
                                    )
                                  )}
                                </span>
                                <span className=""> FREE</span>{" "}
                              </>
                            ) : (
                              currencyFormat(200)
                            )}

                            {Number(
                              cartItems.reduce(
                                (acc, item) => acc + item.qty * item.price,
                                0
                              )
                            ) < 1450 && (
                              <span
                                style={{
                                  fontWeight: "500",
                                  fontSize: "0.8em",
                                }}
                                className="d-block text-dark"
                              >
                                {" "}
                                Delivery above order value ₹ 1450 is free
                              </span>
                            )}
                          </span>
                        </p>
                      </div>

                      {
                        promocodes && (
                          // promocodes.type === "flat" ? (
                          // cartItems.find((value) => {
                          //     return promocodes.for.includes(value.product);
                          //   }) &&
                          <div className="mt-1 justify-content-between d-flex">
                            <p
                              style={{
                                fontSize: "0.9em",
                                color: "grey",
                              }}
                            >
                              Discount
                            </p>
                            <p
                              style={{
                                fontSize: "0.9em",
                                color: "grey",
                              }}
                            >
                              FLAT ₹ {promocodes.discountValue} OFF
                            </p>
                          </div>
                        )
                        // ) : (
                        //   promocodes &&
                        //   promocodes.length > 0 &&
                        //   promocodes[0].type !== "flat" && (
                        //     <div className="mt-1 justify-content-between d-flex">
                        //       <p
                        //         style={{
                        //           fontSize: "0.9em",
                        //           color: "grey",
                        //         }}
                        //       >
                        //         Discount
                        //       </p>
                        //       <p
                        //         style={{
                        //           fontSize: "0.9em",
                        //           color: "grey",
                        //         }}
                        //       >
                        //         {promocodes[0].desc}
                        //       </p>
                        //     </div>
                        //   )
                        // )
                      }
                    </div>
                    <div
                      className="position-relative  "
                      style={{ height: "20px" }}
                    >
                      <div
                        className=" w-100 position-absolute"
                        style={{
                          borderBottom: "2px  dotted #BCB5B5",
                          top: "50%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          height: "20px",
                          borderRadius: "50%",
                          width: "20px",
                          left: "-2%",
                        }}
                      ></div>
                      <div
                        className=" position-absolute "
                        style={{
                          background: "#fff",
                          borderRadius: "50%",
                          height: "20px",
                          width: "20px",
                          right: "-2%",
                        }}
                      ></div>
                    </div>
                    <div className=" p-3 pb-1 justify-content-between d-flex p-lg-4 pb-lg-2 pt-lg-2">
                      <p
                        className=""
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        Total
                      </p>
                      <p
                        style={{
                          fontWeight: "600",
                        }}
                      >
                        {currencyFormat(
                          Number(
                            cartItems.reduce(
                              (acc, item) => acc + item.qty * item.accPrice,
                              0
                            )
                          ) > 1450
                            ? Math.max(
                                0,
                                Number(
                                  cartItems.reduce(
                                    (acc, item) =>
                                      acc + item.qty * item.accPrice,
                                    0
                                  )
                                ) - (promocodes ? promocodes.discountValue : 0)
                              )
                            : Math.max(
                                0,
                                200 +
                                  Number(
                                    cartItems.reduce(
                                      (acc, item) =>
                                        acc + item.qty * item.price,
                                      0
                                    )
                                  ) -
                                  (promocodes ? promocodes.discountValue : 0)
                              )
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h6 className="cart-heading">PAY USING:</h6>
                    <fieldset className="form__group mt-3">
                      <input
                        className="form__input"
                        required
                        type="radio"
                        name="loanType"
                        id="purchase"
                        value="Razorpay"
                        checked={paymentMethod === "Razorpay"}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      />
                      <label className="form__label" htmlFor="purchase">
                        <img
                          src={razorpayLogo}
                          alt="razorpay-logo"
                          className="img-fluid razorpay-logo"
                        />
                        <svg
                          className="form__label-check"
                          viewBox="0 0 512 512"
                          title="check-circle"
                        >
                          <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                        </svg>
                      </label>

                      <input
                        className="form__input"
                        required
                        type="radio"
                        name="loanType"
                        id="refinance"
                        value="PayU"
                        checked={paymentMethod === "PayU"}
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      />
                      <label className="form__label" htmlFor="refinance">
                        <img
                          src={payuLogo}
                          alt="payu-logo"
                          className="img-fluid payu-logo"
                        />
                        <svg
                          className="form__label-check"
                          viewBox="0 0 512 512"
                          width="100"
                          title="check-circle"
                        >
                          <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z" />
                        </svg>
                      </label>
                    </fieldset>

                    {/* <div className="d-flex" style={{ gap: "1em" }}>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          required
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="Razorpay"
                          checked={paymentMethod === "Razorpay"}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <label class="form-check-label" for="exampleRadios1">
                          <img
                            src={razorpayLogo}
                            alt="razorpay-logo"
                            className="img-fluid w-50"
                          ></img>
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          required
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          value="PayU"
                          checked={paymentMethod === "PayU"}
                          onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                        <label class="form-check-label" for="exampleRadios2">
                          <img
                            src={payuLogo}
                            alt="payu-logo"
                            className="img-fluid w-25"
                          ></img>
                        </label>
                      </div>
                    </div> */}
                  </div>
                  {!(
                    name &&
                    email &&
                    mobileNumber &&
                    pincode &&
                    addressLine1 &&
                    city &&
                    state
                  ) && (
                    <small className="text-danger fw-bold">
                      *Fill Billing & Shipping Address
                    </small>
                  )}
                  <div className="mt-3">
                    <button
                      type="Submit"
                      style={{
                        background: "#004dac",
                        color: "#fff",
                      }}
                      name="Place Order"
                      value="Place Order"
                      className="btn p-3 w-100"
                      disabled={
                        name &&
                        email &&
                        mobileNumber &&
                        pincode &&
                        addressLine1 &&
                        city &&
                        state
                          ? false
                          : true
                      }
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="empty-cart">
          <div>
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/ind-website-2.0/test/cart-removebg-preview.png"
              alt=""
              className="img-fluid"
            />
            <h1>Your Cart is Empty</h1>
            <p>
              Looks like you have not added anything to your cart. Go ahead a
              explore top categories of premium products
            </p>

            <Link to={"/"} className="btn  d-block rounded-0 btn-dark">
              Explore
            </Link>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default CartScreen;
