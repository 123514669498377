import {
  GET_PRODUCT_BY_NAME_FAIL,
  GET_PRODUCT_BY_NAME_REQUEST,
  GET_PRODUCT_BY_NAME_SUCCESS,
} from "../Constants/productConstants";

export const productByNameReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRODUCT_BY_NAME_REQUEST:
      return { loading: true };
    case GET_PRODUCT_BY_NAME_SUCCESS:
      return { loading: false, product: action.payload };
    case GET_PRODUCT_BY_NAME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
