import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProductByName } from "../Actions/productActions";
import { addToCart } from "../Actions/cartActions";
import Navbar from "../Components/Navbar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { productHighlights } from "../Components/productDetailsObject";

const ToyProductScreen = () => {
  SwiperCore.use([Navigation, Pagination, Scrollbar]);

  const sliderRef = useRef();
  const [Collapse, setCollapse] = useState(false);
  const [variant, setVariant] = useState(0);
  const [model, setModel] = useState(0);
  const [imageIndex, setImageIndex] = useState(1);
  const [isDimensionOpen, setIsDimensionOpen] = useState(true);
  const [isDescOpen, setIsDescOpen] = useState(false);
  const [isShippingOpen, setIsShippingOpen] = useState(false);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [isDoubtsOne, setIsDoubtsOne] = useState(false);
  const [isDoubtsTwo, setIsDoubtsTwo] = useState(false);
  const [isDoubtsThree, setIsDoubtsThree] = useState(false);
  const [isDoubtsFour, setIsDoubtsFour] = useState(false);
  const [isDoubtsFive, setIsDoubtsFive] = useState(false);
  const [product1, setProduct1] = useState("");
  const [product2, setProduct2] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { name } = useParams();

  const productByName = useSelector((state) => state.productByName);
  const { product: productInfo } = productByName;

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const toggleDimensionCollapse = () => {
    setIsDimensionOpen(!isDimensionOpen);
  };
  const toggleDescCollapse = () => {
    setIsDescOpen(!isDescOpen);
  };
  const toggleShippingCollapse = () => {
    setIsShippingOpen(!isShippingOpen);
  };
  const toggleFaqCollapse = () => {
    setIsFaqOpen(!isFaqOpen);
  };
  const toggleDoubtsOneCollapse = () => {
    setIsDoubtsOne(!isDoubtsOne);
  };
  const toggleDoubtsTwoCollapse = () => {
    setIsDoubtsTwo(!isDoubtsTwo);
  };
  const toggleDoubtsThreeCollapse = () => {
    setIsDoubtsThree(!isDoubtsThree);
  };
  const toggleDoubtsFourCollapse = () => {
    setIsDoubtsFour(!isDoubtsFour);
  };
  const toggleDoubtsFiveCollapse = () => {
    setIsDoubtsFive(!isDoubtsFive);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (name) {
        await dispatch(getProductByName(name));
        setImageIndex(1);
        if (productInfo) {
          if (productInfo.models.length > params.get("color")) {
            setModel(Number(params.get("color")));
          }
        }
      }
    };
    fetchData();
  }, [dispatch, name]);

  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const addCart = () => {
    dispatch(addToCart(productInfo._id, model, 1, 1, variant));
    navigate("/cart");
  };

  const productHighlightsData = productHighlights.find((b) => b.name === name);

  return (
    <>
      <Navbar />
      {productInfo && (
        <div className="container-fluid position-relative">
          <div className="row position-relative">
            <div className="col-lg-8 p-0 ">
              {/* catalogue images */}

              <div className="d-flex position-relative align-items-center bike-product-images justify-content-center">
                <div
                  className="position-absolute slider-count"
                  style={{
                    fontFamily: "Metropolis-bold",
                  }}
                >
                  <h5 className="">
                    ({imageIndex}/{productInfo.productImages[model].length})
                  </h5>
                </div>
                <Swiper
                  navigation={{ clickable: true }}
                  ref={sliderRef}
                  spaceBetween={15}
                  slidesPerView={1}
                  loop={true}
                  breakpoints={{
                    1024: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                  }}
                  slide
                  onSlideChange={(swiper) => {
                    const newIndex =
                      swiper.activeIndex %
                      productHighlightsData.productImages[model].length;
                    if (newIndex === 0) {
                      setImageIndex(
                        productHighlightsData.productImages[model].length
                      );
                    } else {
                      setImageIndex(newIndex);
                    }
                  }}
                  onSwiper={(swiper) => {
                    const newIndex =
                      swiper.activeIndex %
                      productHighlightsData.productImages[model].length;
                    setImageIndex(newIndex);
                  }}
                >
                  {productInfo.productImages[model].map((item) => {
                    return (
                      <SwiperSlide className="p-1 w-100">
                        <img
                          src={item}
                          alt=""
                          className="img-fluid d-block mx-auto"
                        />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>

              {/* ...........mobile details............ */}
              <div className="d-lg-none">
                <div className="p-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h1
                        className="mb-1"
                        style={{
                          fontFamily: "Metropolis-bold",
                          fontSize: "1.7em",
                        }}
                      >
                        {productInfo.name}
                      </h1>
                      <div className="d-flex align-content-center">
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"15"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"15"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"15"}
                        />
                        <Icon
                          icon="ic:round-star-rate"
                          style={{ color: "#ff9000" }}
                          width={"15"}
                        />
                        <Icon
                          icon="ic:round-star-half"
                          style={{ color: "#ff9000" }}
                          width={"15"}
                        />
                        <span
                          className="ms-2"
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {productHighlightsData.reviews.product[1]}
                        </span>
                      </div>
                    </div>
                    <div>
                      <h4
                        className="text-end fw-bold"
                        style={{
                          fontFamily: "Metropolis-medium",
                        }}
                      >
                        {" "}
                        {productInfo.models[model].price
                          ? currencyFormat(productInfo.models[model].price)
                          : currencyFormat(productInfo.accPrice)}
                      </h4>
                      <h2
                        className="text-end "
                        style={{
                          textDecoration: "line-through",
                          fontFamily: "Metropolis-medium",
                        }}
                      >
                        {currencyFormat(
                          productInfo.models[model].slashPrice
                            ? productInfo.models[model].slashPrice
                            : productInfo.slashPrice
                        )}
                      </h2>
                    </div>
                  </div>
                  <hr className="mt-2" />
                  {productInfo.variants.length ? (
                    <>
                      <div className="mt-3 mb-4">
                        <p
                          style={{ fontFamily: "Metropolis-bold" }}
                          className="mb-1"
                        >
                          Variants
                        </p>
                        <div className="d-flex" style={{ gap: "1em" }}>
                          {productInfo.variants.map((vari, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => setVariant(i)}
                              className={`border  d-flex align-items-center justify-content-center p-2 border-dark w-25 text-center rounded-pill ${
                                variant === i && "active-color"
                              }`}
                            >
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {vari.variantName}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <hr className="mt-2" />
                    </>
                  ) : (
                    ""
                  )}
                  {productInfo.variants.length ? (
                    <div className="mt-3 mb-4">
                      <p
                        style={{ fontFamily: "Metropolis-bold" }}
                        className="mb-1"
                      >
                        Colours
                      </p>
                      <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                        {productInfo.variants[variant].models.map(
                          (color, i) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => setModel(i)}
                              className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                                model === i && "active-color"
                              }`}
                            >
                              <div
                                className="me-2 rounded-circle"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  background: `${productInfo.models[color].colorCode}`,
                                }}
                              ></div>
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Metropolis-bold",
                                }}
                              >
                                {productInfo.models[color].colorName}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3 mb-4">
                      <p
                        style={{ fontFamily: "Metropolis-bold" }}
                        className="mb-1"
                      >
                        Colours
                      </p>
                      <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                        {productInfo.models.map((color, i) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setModel(i)}
                            className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                              model === i && "active-color"
                            }`}
                          >
                            <div
                              className="me-2 rounded-circle"
                              style={{
                                width: "20px",
                                height: "20px",
                                background: `${color.colorCode}`,
                              }}
                            ></div>
                            <span
                              style={{
                                fontSize: "14px",
                                fontFamily: "Metropolis-bold",
                              }}
                            >
                              {color.colorName}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  <hr className="mt-2" />
                  <div className="mt-3">
                    <Link
                      to={"/find-e-bicycle-store"}
                      className="btn w-100 p-lg-2 pt-3 pb-3  text-em rounded-5"
                      style={{
                        fontSize: "14px",
                        border: "1px solid #004DAC",
                        fontFamily: "Metropolis-bold",
                      }}
                    >
                      BUY NOW AT YOUR NEAREST DEALERSHIP
                    </Link>
                    <div className="d-flex mt-3" style={{ gap: "1em" }}>
                      <div className="w-100">
                        <a
                          href={productHighlightsData.links.amazon}
                          target="_blank"
                          className="btn d-flex justify-content-center align-items-center w-100 p-2 text-light rounded-5"
                          style={{
                            border: "1px solid #004DAC",
                            fontSize: "14px",
                            gap: "1em",
                            background: "#004dac",
                            fontFamily: "Metropolis-bold",
                          }}
                          rel="noreferrer"
                        >
                          BUY ON{" "}
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/bi_amazon.png"
                            className="img-fluid "
                            style={{ width: "25px" }}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="w-100">
                        <a
                          href={productHighlightsData.links.flipkart}
                          target="_blank"
                          className="btn d-flex justify-content-center align-items-center w-100 p-2 text-light rounded-5"
                          style={{
                            border: "1px solid #004DAC",
                            fontSize: "14px",
                            gap: "1em",
                            background: "#004dac",
                            fontFamily: "Metropolis-bold",
                          }}
                          rel="noreferrer"
                        >
                          BUY ON
                          <img
                            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/simple-icons_flipkart.png"
                            className="img-fluid "
                            style={{ width: "23px" }}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* )} */}
                </div>
                <div className="line-break"></div>
                <div className="p-3">
                  <div className="row pt-1 pb-1  pb-0 justify-content-around">
                    <div className="col-4 text-center p-0">
                      <Icon
                        icon="octicon:arrow-both-16"
                        width={30}
                        style={{ color: "#004dac" }}
                      />
                      <h5
                        className="fw-bold mb-0 mt-1 text-center"
                        style={{ fontFamily: "Metropolis-bold" }}
                      >
                        {productHighlightsData.productSpecs.range}
                      </h5>
                      <p style={{ fontSize: "14px" }} className="m-0">
                        KM RANGE
                      </p>
                    </div>
                    <div className="col-4 text-center p-0">
                      <Icon
                        icon="ic:round-speed"
                        width={30}
                        style={{ color: "#004dac" }}
                      />

                      <h5
                        className="fw-bold mt-1 mb-0 text-center"
                        style={{ fontFamily: "Metropolis-bold" }}
                      >
                        {productHighlightsData.productSpecs.speed}
                      </h5>
                      <p style={{ fontSize: "14px" }} className="m-0">
                        KM/HR SPEED
                      </p>
                    </div>
                    <div className="col-4 text-center p-0">
                      <Icon
                        icon="fluent:battery-9-24-regular"
                        width={30}
                        style={{ color: "#004dac" }}
                      />

                      <h5
                        className="fw-bold mt-1 mb-0 text-center"
                        style={{ fontFamily: "Metropolis-bold" }}
                      >
                        {productHighlightsData.productSpecs.battery}
                      </h5>
                      <p className="m-0" style={{ fontSize: "14px" }}>
                        AH BATTERY
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="line-break"></div>

              {/* infographic creative */}
              <div className="product-gif position-relative">
                <div
                  className="position-absolute"
                  style={{
                    width: "100%",
                    height: "100%",
                    zIndex: "1",
                    background: "rgba(0,0,0,0.5)",
                  }}
                ></div>

                <video
                  playsInline
                  loop
                  className=" ratio d-block ratio-16x9"
                  autoPlay={true}
                  disableRemotePlayback
                  preload="auto"
                  webkit-playsinline
                  style={{
                    width: "100%",

                    objectFit: "cover",
                  }}
                  muted={true}
                >
                  <source
                    media="(max-width:650px)"
                    src={productHighlightsData.bikeGif.url}
                    type="video/mp4"
                  />
                  <source
                    media="(min-width:651px)"
                    src={productHighlightsData.bikeGif.url}
                    type="video/ogg"
                  />
                </video>
                <div>
                  <div className="product-gif-heading w-100">
                    <h2>{productHighlightsData.bikeGif.tagline}</h2>
                  </div>
                  <div className="product-gif-specs">
                    <div class="spec-box">
                      <h3 className="mb-1 mt-1 text-center">
                        {productHighlightsData.productSpecs.battery}
                      </h3>
                      <p className="m-0 text-center">AH Battery</p>
                    </div>
                    <div class="spec-divider"></div>
                    <div class="spec-box">
                      <h3 className="mb-1 mt-1 text-center">
                        {productHighlightsData.productSpecs.range}
                      </h3>
                      <p className="m-0 text-center">KM Range</p>
                    </div>
                    <div class="spec-divider"></div>
                    <div class="spec-box ">
                      <h3 className="mb-1 mt-1 text-center">
                        {productHighlightsData.productSpecs.speed}
                      </h3>
                      <p className="m-0 text-center">"KM/HR Top Speed"</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="line-break"></div>

              {/* USP Section */}
              <div className="structure-design ps-lg-5 pe-lg-5 ">
                <h1
                  className="mb-4 structure-main-heading text-em heading-homepage"
                  style={{ fontFamily: "Metropolis-black" }}
                >
                  What makes {productInfo.name} special?
                </h1>
                <p>{productInfo.description}</p>
              </div>

              <div className="line-break"></div>

              <div className="p-3 mt-3 ps-lg-5 pe-lg-5 ">
                <h1
                  className="mb-4 structure-main-heading text-em overflow"
                  style={{ fontFamily: "Passion One" }}
                >
                  Compare The Best
                </h1>{" "}
                {/* <div className="row">
                  <div className="col-3"></div>
                  <div className="col-4 p-0">
                    <img
                      className="img-fluid"
                      src={bikeInfo.cartImages[model]}
                    ></img>
                    <div className="product-select">
                      <select
                        onChange={(e) => {
                          setBike1(e.target.value);
                        }}
                        className="form-select w-100 text-light text-center mobile-bike-select"
                        required
                        style={{
                          padding: "0.5em",
                          backgroundColor: "#004dac",
                        }}
                        id="exampleFormControlInput1"
                      >
                        {bikeSpecs.map((item, index) => (
                          <option
                            selected={item.name === bike1}
                            key={index}
                            value={item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-1 d-lg-block d-none"></div>

                  <div className="col-4 p-0 ms-3 ms-lg-0">
                    <img
                      className="img-fluid"
                      src={bikeSpecs.find((bike) => bike.name === bike2)?.image}
                    ></img>
                    <div className="product-select">
                      <select
                        onChange={(e) => {
                          setBike2(e.target.value);
                        }}
                        className="form-select w-100 text-light text-center mobile-bike-select"
                        required
                        style={{
                          padding: "0.5em",
                          backgroundColor: "#004dac",
                        }}
                        id="exampleFormControlInput1"
                      >
                        {bikeSpecs.map((item, index) => (
                          <option
                            key={index}
                            value={item.name}
                            selected={item.name === bike2}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div> */}
                <div className="row mt-3">
                  <div className="col-3 pe-0">
                    <table>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Price
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Frame
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Gears
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Range
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Tyre
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Suspension
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Display
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Battery
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Rider Weight Capacity
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Rider Height
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-feature"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #ffffff",
                          }}
                        >
                          Frame Warranty
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* <div
                    className="col-4"
                    style={{
                      border: "1px solid #C5C5C5",
                      borderRadius: "5px",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    <table
                      className="text-center"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike1)?.price}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike1)?.frame}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike1)?.gears}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike1)?.range}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike1)?.tyre}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike1)
                              ?.suspension
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike1)
                              ?.display
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike1)
                              ?.battery
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike1)
                              ?.weight
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike1)
                              ?.height
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike1)
                              ?.warranty
                          }
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="col-1 d-lg-block d-none"></div>
                  <div
                    className="col-4 ms-3 ms-lg-0"
                    style={{
                      border: "1px solid #C5C5C5",
                      borderRadius: "5px",
                      backgroundColor: "#f4f4f4",
                    }}
                  >
                    <table
                      className="text-center"
                      style={{ width: "-webkit-fill-available" }}
                    >
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike2)?.price}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike2)?.frame}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike2)?.gears}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike2)?.range}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {bikeSpecs.find((bike) => bike.name === bike2)?.tyre}
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike2)
                              ?.suspension
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike2)
                              ?.display
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike2)
                              ?.battery
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike2)
                              ?.weight
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                            borderBottom: "1px solid #C5C5C5",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike2)
                              ?.height
                          }
                        </td>
                      </tr>
                      <tr className="comparison-feature-block">
                        <td
                          className="pt-lg-3 pb-lg-3 pt-2 pb-2 comparison-specs"
                          style={{
                            color: "#004dac",
                            fontFamily: "metropolis-bold",
                          }}
                        >
                          {
                            bikeSpecs.find((bike) => bike.name === bike2)
                              ?.warranty
                          }
                        </td>
                      </tr>
                    </table>
                  </div> */}
                </div>
              </div>

              <div className="line-break"></div>

              <div className="lifeStyle-breaker">
                <img
                  className="w-100"
                  src={productHighlightsData.lifestyleImages[model]}
                  alt=""
                />
              </div>

              <div className="line-break"></div>

              <div className="specs-list p-3 ps-lg-5 pe-lg-5">
                <div className="mb-3">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample1"
                    aria-expanded={isDimensionOpen ? "true" : "false"}
                    aria-controls="collapseExample"
                    onClick={toggleDimensionCollapse}
                  >
                    <h1
                      className="structure-main-heading text-em heading-homepage"
                      style={{ fontFamily: "Metropolis-black" }}
                    >
                      Dimensions
                    </h1>
                    <span className="iconify">
                      {isDimensionOpen ? (
                        <i className="fa-solid fa-minus"></i>
                      ) : (
                        <i className="fa-solid fa-plus"></i>
                      )}
                    </span>
                  </div>
                  <div
                    className="collapse show mt-lg-3 ps-lg-4"
                    id="collapseExample1"
                  >
                    <div className="row">
                      <div className="col-md-6 d-lg-none">
                        <img
                          src={productHighlightsData.dimensionImage[model]}
                          className="img-fluid"
                          alt="Bike Dimension"
                          style={{ translate: "-3% 0%" }}
                        />
                      </div>
                      <div className="col-md-6">
                        {productInfo.specs.map(
                          (item, i) =>
                            i <= 5 && (
                              <div key={i} className="row ">
                                <div className="col-md-6 col-5">
                                  <p className="m-0 specs-feature">
                                    {item.name}
                                  </p>
                                </div>
                                <div className="col-md-6 col-7">
                                  <p className="m-0 specs-feature">
                                    {item.value}
                                  </p>
                                </div>
                                <hr
                                  className="p-0"
                                  style={{ marginTop: "8px" }}
                                />
                              </div>
                            )
                        )}
                        {productInfo.specs.map(
                          (item, i) =>
                            i > 5 && (
                              <div key={i} className="collapse" id="collapseEx">
                                <div className="row">
                                  <div className="col-md-6 col-5 ">
                                    <p className="m-0 specs-feature">
                                      {item.name}
                                    </p>
                                  </div>
                                  <div className="col-md-6 col-7">
                                    <p className="m-0 specs-feature">
                                      {item.value}
                                    </p>
                                  </div>
                                  <hr
                                    className="p-0"
                                    style={{ marginTop: "8px" }}
                                  />
                                </div>
                              </div>
                            )
                        )}
                        <button
                          className="spec-btn mt-4 mb-4 mx-auto d-block "
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseEx"
                          aria-expanded="false"
                          onClick={() => setCollapse(!Collapse)}
                          aria-controls="collapseExample"
                        >
                          {Collapse ? "Collapse" : " See More"}
                        </button>
                      </div>
                      <div className="col-md-6 dimension-image">
                        <img
                          src={productHighlightsData.dimensionImage[model]}
                          className="img-fluid d-none d-lg-block"
                          alt="Bike Dimension"
                          style={{ translate: "0% -10%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!isDimensionOpen && (
                  <hr className="p-0" style={{ marginTop: "16px" }} />
                )}
                <div>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample4"
                    aria-expanded={isShippingOpen ? "true" : "false"}
                    aria-controls="collapseExample"
                    onClick={toggleShippingCollapse}
                  >
                    <h1
                      className="structure-main-heading text-em heading-homepage"
                      style={{ fontFamily: "Metropolis-black" }}
                    >
                      Shipping Terms
                    </h1>
                    <span className="iconify">
                      {isShippingOpen ? (
                        <i className="fa-solid fa-minus"></i>
                      ) : (
                        <i className="fa-solid fa-plus"></i>
                      )}
                    </span>
                  </div>

                  <div className="collapse mt-3" id="collapseExample4">
                    <p className="ps-4">
                      <b>Shipping Terms:</b> Available shipping options will be
                      displayed during the checkout process. Any timeframe
                      provided by EMotorad for when the Product(s) may ship or
                      be delivered is a good faith estimate. While we do our
                      best to meet that timeframe, it is not a guarantee. Actual
                      delivery of your order can be impacted by many events for
                      which EMotorad cannot be held liable. If you no longer
                      have use for an item due to a late delivery, please
                      contact our Customer Service Department immediately.
                    </p>
                    <p className="ps-4">
                      <b>Inspection on Receipt:</b> Customers are advised to
                      immediately inspect the packaging for any signs of damage
                      upon receiving the goods. If any obvious damage is
                      observed, customers have the option to reject the delivery
                      and should contact EMotorad promptly.
                    </p>
                    <p className="ps-4">
                      <b>Reporting Shipping Damage:</b> Claims for shipping
                      damage must be made within 24 hours of receiving the
                      product. EMotorad will not accept claims for shipping
                      damage reported later than this timeframe.
                    </p>
                    <p className="ps-4">
                      <b>Rejecting Damaged Goods:</b> If customers notice
                      obvious damage to the product packaging, they have the
                      right to reject the delivery. Customers should contact
                      EMotorad immediately to initiate the claims process.
                    </p>
                    <p className="ps-4">
                      <b>Warranty Exclusions:</b> Shipping damage caused by
                      neglect, misuse, abuse, or accidents is not covered under
                      the warranty. Normal wear and tear, incorrect
                      installation, alterations, and tampering damage are also
                      excluded from warranty coverage.
                    </p>
                    <p className="ps-4">
                      <b>Geographical Limitations:</b> The warranty is valid
                      only within the geographical boundaries of the Indian
                      subcontinent.
                    </p>
                    <p className="ps-4">
                      <b>Change of Warranty Policy:</b> EMotorad reserves the
                      right to change the warranty policy at its sole discretion
                      without any prior notice.
                    </p>
                    <p className="ps-4">
                      <b>Contact Information:</b> For offline and online
                      purchases, customers should reach out to the EMotorad
                      service team regarding any shipping damage claims or
                      warranty-related queries. Please note that these terms are
                      subject to change by EMotorad, and customers are advised
                      to refer to the latest terms and conditions provided by
                      the company.
                    </p>
                    <hr className="p-0" style={{ marginTop: "14px" }} />
                  </div>
                </div>
                {!isShippingOpen && (
                  <hr className="p-0" style={{ marginTop: "16px" }} />
                )}
                <div>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample3"
                    aria-expanded={isDescOpen ? "true" : "false"}
                    aria-controls="collapseExample"
                    onClick={toggleDescCollapse}
                  >
                    <h1
                      className="structure-main-heading text-em heading-homepage"
                      style={{ fontFamily: "Metropolis-black" }}
                    >
                      Still Have Doubts?
                    </h1>
                    <span className="iconify">
                      {isDescOpen ? (
                        <i className="fa-solid fa-minus"></i>
                      ) : (
                        <i className="fa-solid fa-plus"></i>
                      )}
                    </span>
                  </div>

                  <div className="collapse p-2" id="collapseExample3">
                    <div className="p-3">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSubExample2"
                        aria-expanded={isDoubtsTwo ? "true" : "false"}
                        aria-controls="collapseExample"
                        onClick={toggleDoubtsTwoCollapse}
                      >
                        <p className="fw-bold" style={{ marginBottom: "0px" }}>
                          Why are we priced higher than ordinary cycles?
                        </p>
                        <span className="iconify">
                          {isDoubtsTwo ? (
                            <i className="fa-solid fa-minus"></i>
                          ) : (
                            <i className="fa-solid fa-plus"></i>
                          )}
                        </span>
                      </div>
                      <div className="collapse mt-3" id="collapseSubExample2">
                        <img
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/stillHaveDoubts/Artboard+1+(2).jpg"
                          className="img-fluid"
                        ></img>
                      </div>
                    </div>
                    <div className="p-3">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSubExample3"
                        aria-expanded={isDoubtsThree ? "true" : "false"}
                        aria-controls="collapseExample"
                        onClick={toggleDoubtsThreeCollapse}
                      >
                        <p className="fw-bold" style={{ marginBottom: "0px" }}>
                          How are we different from other electric cycle brands?
                        </p>
                        <span className="iconify">
                          {isDoubtsThree ? (
                            <i className="fa-solid fa-minus"></i>
                          ) : (
                            <i className="fa-solid fa-plus"></i>
                          )}
                        </span>
                      </div>
                      <div className="collapse mt-3" id="collapseSubExample3">
                        <img
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/stillHaveDoubts/Artboard+2+(2).jpg"
                          className="img-fluid"
                        ></img>
                      </div>
                    </div>
                    <div className="p-3">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseSubExample4"
                        aria-expanded={isDoubtsFour ? "true" : "false"}
                        aria-controls="collapseExample"
                        onClick={toggleDoubtsFourCollapse}
                      >
                        <p className="fw-bold" style={{ marginBottom: "0px" }}>
                          Why should you trust us?
                        </p>
                        <span className="iconify">
                          {isDoubtsFour ? (
                            <i className="fa-solid fa-minus"></i>
                          ) : (
                            <i className="fa-solid fa-plus"></i>
                          )}
                        </span>
                      </div>
                      <div className="collapse mt-3" id="collapseSubExample4">
                        <img
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/stillHaveDoubts/Artboard+3+(1).jpg"
                          className="img-fluid"
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="p-0" style={{ marginTop: "16px" }} />

                <div>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExample5"
                    aria-expanded={isFaqOpen ? "true" : "false"}
                    aria-controls="collapseExample"
                    onClick={toggleFaqCollapse}
                  >
                    <h1
                      className="structure-main-heading text-em heading-homepage"
                      style={{ fontFamily: "Metropolis-black" }}
                    >
                      FAQs
                    </h1>
                    <span className="iconify">
                      {isFaqOpen ? (
                        <i className="fa-solid fa-minus"></i>
                      ) : (
                        <i className="fa-solid fa-plus"></i>
                      )}
                    </span>
                  </div>

                  <div className="collapse mt-3" id="collapseExample5">
                    {productHighlightsData.faq.map((faq, index) => (
                      <div key={index}>
                        <input
                          type="checkbox"
                          id={`question${index + 1}`}
                          name="q"
                          className="questions"
                        ></input>
                        <label
                          htmlFor={`question${index + 1}`}
                          className="question"
                        >
                          {index + 1}. {faq.question}
                        </label>
                        <div
                          className="answers"
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        ></div>
                        <hr className="p-0" style={{ marginTop: "16px" }} />
                      </div>
                    ))}
                  </div>
                </div>
                {!isFaqOpen && (
                  <hr className="p-0" style={{ marginTop: "16px" }} />
                )}
              </div>

              <div className="line-break"></div>

              <div className="lifeStyle-breaker">
                <img
                  className="w-100"
                  src={productHighlightsData.lifestyleImages[model]}
                  alt=""
                />
              </div>

              <div className="line-break"></div>
              <div className="reviews-div  p-3 ps-lg-4">
                <div className=" mb-lg-4 reviews-sub-div">
                  <div className="">
                    <h1
                      className="mb-4 structure-main-heading text-em heading-homepage"
                      style={{ fontFamily: "Metropolis-black" }}
                    >
                      Some Rider Love!
                    </h1>
                    <p className="product-text mb-1 mb-lg-3">
                      More than 90% of our customers who have bought EMotorad
                      products have loved them. Do take a look at what some of
                      them have to say:
                    </p>
                    <div
                      className="mb-2 mb-lg-5"
                      style={{ textAlign: "center" }}
                    >
                      <p
                        style={{
                          margin: 0,
                          padding: 0,
                          fontWeight: "600",
                          fontSize: "1.3em",
                        }}
                      >
                        <span style={{ fontSize: "2em" }}>
                          {productHighlightsData.reviews.product[0]}
                        </span>
                        /5
                      </p>
                      <Icon icon="emojione:star" />
                      <Icon icon="emojione:star" />
                      <Icon icon="emojione:star" />
                      <Icon icon="emojione:star" />
                      <Icon icon="emojione:star" />
                      <p className="reviews-text">1965 reviews</p>
                    </div>
                    <div className="row reviews-logo-sec">
                      <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                        <img
                          className="reviews-logo"
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                          alt="Amazon Logo"
                        />
                        <div className="d-flex flex-column align-items-center reviews-logo-div">
                          <div className="d-flex">
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                          </div>
                          <p
                            style={{ margin: 0, padding: 0 }}
                            className="review-logo-text"
                          >
                            1640 reviews
                          </p>
                        </div>
                      </div>
                      <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                        <img
                          className="reviews-logo"
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                          alt="Amazon Logo"
                        />
                        <div className="d-flex flex-column align-items-center reviews-logo-div">
                          <div className="d-flex">
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                          </div>
                          <p
                            style={{ margin: 0, padding: 0 }}
                            className="review-logo-text"
                          >
                            193 reviews
                          </p>
                        </div>
                      </div>
                      <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                        <img
                          className="reviews-logo"
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                          alt="Amazon Logo"
                        />
                        <div className="d-flex flex-column align-items-center reviews-logo-div">
                          <div className="d-flex">
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                            <Icon icon="emojione:star" className="star-logo" />
                          </div>
                          <p
                            style={{ margin: 0, padding: 0 }}
                            className="review-logo-text"
                          >
                            132 reviews
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-lg-none">
                  <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                    <img
                      className="reviews-logo"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/amazon-logo_2.png"
                      alt="Amazon Logo"
                    />
                    <div className="d-flex flex-column align-items-center reviews-logo-div">
                      <div className="d-flex">
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                      </div>
                      <p
                        style={{ margin: 0, padding: 0 }}
                        className="review-logo-text"
                      >
                        1640 reviews
                      </p>
                    </div>
                  </div>
                  <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                    <img
                      className="reviews-logo"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/flipkart-logo1.png"
                      alt="Amazon Logo"
                    />
                    <div className="d-flex flex-column align-items-center reviews-logo-div">
                      <div className="d-flex">
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                      </div>
                      <p
                        style={{ margin: 0, padding: 0 }}
                        className="review-logo-text"
                      >
                        193 reviews
                      </p>
                    </div>
                  </div>
                  <div className="col-4 pe-2 d-flex justify-content-center align-items-center">
                    <img
                      className="reviews-logo"
                      src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/Google__G__logo.png"
                      alt="Amazon Logo"
                    />
                    <div className="d-flex flex-column align-items-center reviews-logo-div">
                      <div className="d-flex">
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                        <Icon icon="emojione:star" className="star-logo" />
                      </div>
                      <p
                        style={{ margin: 0, padding: 0 }}
                        className="review-logo-text"
                      >
                        132 reviews
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line-break"></div>
            </div>

            {/* .........................Fixed Desktop bike details................................ */}
            <div
              className="col-lg-4 d-none d-lg-block p-0 position-sticky"
              style={{ height: "92vh", overflowY: "auto", top: "10%" }}
            >
              <div className="p-4 pe-5">
                <div className="d-flex justify-content-between">
                  <div>
                    <h1
                      className="mb-1"
                      style={{
                        fontFamily: "Metropolis-bold",
                        fontSize: "2.5em",
                      }}
                    >
                      <em>{productInfo.name}</em>
                    </h1>
                    <div className="d-flex align-content-center">
                      <Icon
                        icon="ic:round-star-rate"
                        style={{ color: "#ff9000" }}
                        width={"20"}
                      />
                      <Icon
                        icon="ic:round-star-rate"
                        style={{ color: "#ff9000" }}
                        width={"20"}
                      />
                      <Icon
                        icon="ic:round-star-rate"
                        style={{ color: "#ff9000" }}
                        width={"20"}
                      />
                      <Icon
                        icon="ic:round-star-rate"
                        style={{ color: "#ff9000" }}
                        width={"20"}
                      />
                      <Icon
                        icon="ic:round-star-half"
                        style={{ color: "#ff9000" }}
                        width={"20"}
                      />
                      <span
                        className="ms-2"
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {/* {bikeHighlightsData.reviews.product[1]} Reviews */}
                      </span>
                    </div>
                  </div>
                  <div>
                    <h3
                      className="text-end fw-bold"
                      style={{
                        fontFamily: "Metropolis-medium",
                      }}
                    >
                      {" "}
                      {productInfo.models[
                        productInfo.variants.length
                          ? productInfo.variants[variant].models[model]
                          : model
                      ].price
                        ? currencyFormat(
                            productInfo.models[
                              productInfo.variants.length
                                ? productInfo.variants[variant].models[model]
                                : model
                            ].price
                          )
                        : currencyFormat(productInfo.accPrice)}
                    </h3>
                    <h2
                      className="text-end "
                      style={{
                        textDecoration: "line-through",
                        fontFamily: "",
                      }}
                    >
                      {currencyFormat(
                        productInfo.models[
                          productInfo.variants.length
                            ? productInfo.variants[variant].models[model]
                            : model
                        ].slashPrice
                          ? productInfo.models[
                              productInfo.variants.length
                                ? productInfo.variants[variant].models[model]
                                : model
                            ].slashPrice
                          : productInfo.slashPrice
                      )}
                    </h2>
                  </div>
                </div>
                <hr className="m-0" />
                <div className="mt-3 pb-1">
                  <div className="d-flex mt-2 align-content-center">
                    <Icon icon="ph:seal-check-light" width={23} />{" "}
                    <p
                      style={{
                        fontSize: "15px",
                        fontFamily: "Metropolis-medium",
                      }}
                      className="ms-3"
                    >
                      {productHighlightsData.productSpecs.warranty}
                    </p>
                  </div>
                  <div className="d-flex  align-content-center">
                    <Icon icon="la:shipping-fast" width={23} />
                    <p
                      style={{
                        fontSize: "15px",
                        fontFamily: "Metropolis-medium",
                      }}
                      className="ms-3"
                    >
                      In Stock & Ready to Ship
                    </p>
                  </div>
                  <div className="d-flex  align-content-center">
                    <Icon
                      icon="material-symbols-light:speed-outline"
                      width={23}
                    />
                    <p
                      className="ms-3"
                      style={{
                        fontSize: "15px",
                        fontFamily: "Metropolis-medium",
                      }}
                    >
                      {productHighlightsData.usp}
                    </p>
                  </div>
                </div>
                <hr className="m-0" />
                <div className="d-flex mt-3 pb-1 justify-content-around">
                  <div className=" text-center">
                    <Icon
                      icon="octicon:arrow-both-16"
                      width={30}
                      style={{ color: "#004dac" }}
                    />
                    <h5
                      className="fw-bold mb-0 mt-1 text-center"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      {productHighlightsData.productSpecs.range}
                    </h5>
                    <p className="">KM RANGE</p>
                  </div>
                  <div className=" text-center">
                    <Icon
                      icon="ic:round-speed"
                      width={30}
                      style={{ color: "#004dac" }}
                    />

                    <h5
                      className="fw-bold mt-1 mb-0 text-center"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      {productHighlightsData.productSpecs.speed}
                    </h5>
                    <p className="">KM/HR SPEED</p>
                  </div>{" "}
                  <div className=" text-center">
                    <Icon
                      icon="fluent:battery-9-24-regular"
                      width={30}
                      style={{ color: "#004dac" }}
                    />

                    <h5
                      className="fw-bold mt-1 mb-0 text-center"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      {productHighlightsData.productSpecs.battery}
                    </h5>
                    <p className="">AH BATTERY</p>
                  </div>{" "}
                </div>
                <hr className="mt-2" />
                {productInfo.variants.length ? (
                  <>
                    <div className="mt-3 mb-4">
                      <p style={{ fontFamily: "Metropolis-bold" }}>VARIANTS</p>
                      <div className="d-flex" style={{ gap: "1em" }}>
                        {productInfo.variants.map((vari, i) => (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => setVariant(i)}
                            className={`border  d-flex align-items-center p-2 border-dark w-25 justify-content-center rounded-pill ${
                              variant === i && "active-color"
                            }`}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                fontFamily: "Metropolis-bold",
                              }}
                            >
                              {vari.variantName}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <hr />
                  </>
                ) : (
                  ""
                )}
                {productInfo.variants.length ? (
                  <div className="mt-3 mb-4">
                    <p style={{ fontFamily: "Metropolis-bold" }}>COLOURS</p>
                    <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                      {productInfo.variants[variant].models.map((color, i) => (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setModel(i)}
                          className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                            model === i && "active-color"
                          }`}
                        >
                          <div
                            className="me-2 rounded-circle"
                            style={{
                              width: "20px",
                              height: "20px",
                              background: `${productInfo.models[color].colorCode}`,
                            }}
                          ></div>
                          <span
                            style={{
                              fontSize: "15px",
                              fontFamily: "Metropolis-bold",
                            }}
                          >
                            {productInfo.models[color].colorName}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 mb-4">
                    <p style={{ fontFamily: "Metropolis-bold" }}>COLOURS</p>
                    <div className="d-flex flex-wrap" style={{ gap: "1em" }}>
                      {productInfo.models.map((color, i) => (
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => setModel(i)}
                          className={`border  d-flex align-items-center p-2 border-dark rounded-pill ${
                            model === i && "active-color"
                          }`}
                        >
                          <div
                            className="me-2 rounded-circle"
                            style={{
                              width: "20px",
                              height: "20px",
                              background: `${color.colorCode}`,
                            }}
                          ></div>
                          <span
                            style={{
                              fontSize: "15px",
                              fontFamily: "Metropolis-bold",
                            }}
                          >
                            {color.colorName}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <hr />
                <div className="mt-4">
                  <Link
                    to={"/find-e-bicycle-store"}
                    className="btn w-100 p-3 text-em rounded-5"
                    style={{
                      fontSize: "14px",
                      border: "1px solid #004DAC",
                      fontFamily: "Metropolis-bold",
                    }}
                  >
                    BUY NOW AT YOUR NEAREST DEALERSHIP
                  </Link>
                  <div className="d-flex mt-3 " style={{ gap: "2em" }}>
                    <div className="w-100">
                      <a
                        href={productHighlightsData.links.amazon}
                        target="_blank"
                        className="btn d-flex justify-content-center align-items-center w-100 p-3 text-light rounded-5"
                        style={{
                          border: "1px solid #004DAC",
                          fontSize: "15px",
                          gap: "1em",
                          background: "#004dac",
                          fontFamily: "Metropolis-bold",
                        }}
                        rel="noreferrer"
                      >
                        BUY ON
                        <img
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/bi_amazon.png"
                          className="img-fluid "
                          style={{ width: "20px" }}
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="w-100">
                      <a
                        href={productHighlightsData.links.flipkart}
                        target="_blank"
                        className="btn d-flex justify-content-center align-items-center w-100 p-3 text-light rounded-5"
                        style={{
                          border: "1px solid #004DAC",
                          fontSize: "15px",
                          gap: "1em",
                          background: "#004dac",
                          fontFamily: "Metropolis-bold",
                        }}
                        rel="noreferrer"
                      >
                        BUY ON
                        <img
                          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/general+image/simple-icons_flipkart.png"
                          className="img-fluid "
                          style={{ width: "20px" }}
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="background-em d-flex position-sticky align-items-center justify-content-center p-3"
                style={{ gap: "3em", bottom: "0%" }}
              >
                <div>
                  {productInfo.slashPrice > 0 && (
                    <h4
                      className="text-light text-center mb-0"
                      style={{ fontFamily: "Metropolis-bold" }}
                    >
                      {Math.trunc(
                        (((productInfo.models[
                          productInfo.variants.length
                            ? productInfo.variants[variant].models[model]
                            : model
                        ].slashPrice
                          ? productInfo.models[
                              productInfo.variants.length
                                ? productInfo.variants[variant].models[model]
                                : model
                            ].slashPrice
                          : productInfo.slashPrice) -
                          (productInfo.models[
                            productInfo.variants.length
                              ? productInfo.variants[variant].models[model]
                              : model
                          ].price
                            ? productInfo.models[
                                productInfo.variants.length
                                  ? productInfo.variants[variant].models[model]
                                  : model
                              ].price
                            : productInfo.accPrice)) /
                          (productInfo.models[
                            productInfo.variants.length
                              ? productInfo.variants[variant].models[model]
                              : model
                          ].slashPrice
                            ? productInfo.models[
                                productInfo.variants.length
                                  ? productInfo.variants[variant].models[model]
                                  : model
                              ].slashPrice
                            : productInfo.slashPrice)) *
                          100
                      )}
                      {"%"} OFF
                    </h4>
                  )}{" "}
                  <div className="bg-light rounded-5  text-center text-em ">
                    <h4 style={{ fontFamily: "Metropolis-bold" }}>SALE</h4>
                  </div>
                  <small
                    style={{
                      fontSize: "0.8em",
                      fontFamily: "Metropolis-medium",
                    }}
                    className=" d-block text-center text-light "
                  >
                    Limited Period Offer
                  </small>
                </div>

                <div className="">
                  {productInfo.inStock ? (
                    productInfo.models[
                      productInfo.variants.length
                        ? productInfo.variants[variant].models[model]
                        : model
                    ].inStock ? (
                      <button
                        className="btn btn-light w-100 text-em border-0 rounded-5 pt-3 pb-3 p-3 ps-5 pe-5"
                        style={{
                          padding: "1em 4em",
                          fontFamily: "Metropolis-bold",
                        }}
                        onClick={() =>
                          addCart(productInfo._id, model, 1, 1, variant)
                        }
                      >
                        BUY NOW
                      </button>
                    ) : (
                      <div
                        className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                        style={{
                          fontFamily: "Metropolis-bold",
                        }}
                      >
                        OUT OF STOCK
                      </div>
                    )
                  ) : (
                    <div
                      className="btn btn-light w-100 text-em disabled border-0 rounded-5 pt-3 pb-3 p-3 text-decoration-none"
                      style={{
                        fontFamily: "Metropolis-bold",
                      }}
                      href=""
                    >
                      OUT OF STOCK
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ToyProductScreen;
