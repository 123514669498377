import React, { useEffect, useState, useRef } from "react";
import Navbar from "../Components/Navbar";
import WhatsAppBot from "../Components/WhatsAppBot";
import { getAllBikes } from "../Actions/bikeActions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Meta from "../Components/Meta";
import Footer from "../Components/Footer";
import CallBtn from "../Components/CallBtn";
import "../CSS/all-products.css";
import "@splidejs/splide/css";
import TestRide from "../Components/TestRide";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Loder from "../Components/Loder";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Icon } from "@iconify/react";
// import 'swiper/modules/navigation/navigation.min.css'

// Import the CSS for blur effect

const AllBikesScreen = () => {
  // const [xFactorModel, setXFactorModel] = useState(0);

  // const [bikeIndex, setBikeIndex] = useState();
  SwiperCore.use([Navigation, Pagination]);
  const currencyFormat = (num) => {
    return "₹" + " " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const isDesktop = window.innerWidth >= 800;
  const dispatch = useDispatch();

  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  const [modelDesireStates, setModelDesireStates] = useState(
    bikes
      ? Array(
          bikes.filter(
            (item) =>
              item.name !== "EMX" &&
              item.name !== "EMX Aqua" &&
              item.subCategory === "desire"
          ).length
        ).fill(0)
      : []
  );
  const [modelXStates, setModelXStates] = useState(
    bikes
      ? Array(
          bikes.filter(
            (item) =>
              item.name !== "EMX" &&
              item.name !== "EMX Aqua" &&
              item.subCategory === "x-factor"
          ).length
        ).fill(0)
      : []
  );

  const handleDesireColorClick = (bikeIndex, colorIndex) => {
    setModelDesireStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[bikeIndex] = colorIndex;
      return newStates;
    });
  };
  const handleXColorClick = (bikeIndex, colorIndex) => {
    setModelXStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[bikeIndex] = colorIndex;
      return newStates;
    });
  };
  useEffect(() => {
    if (!bikes) {
      dispatch(getAllBikes());
    }
  }, []);

  useEffect(() => {
    if (bikes) {
      setModelDesireStates(
        Array(
          bikes.filter(
            (item) =>
              item.name !== "EMX" &&
              item.name !== "EMX Aqua" &&
              item.subCategory === "desire"
          ).length
        ).fill(0)
      );
      setModelXStates(
        Array(
          bikes.filter(
            (item) =>
              item.name !== "EMX" &&
              item.name !== "EMX Aqua" &&
              item.subCategory === "x-factor"
          ).length
        ).fill(0)
      );
    }
  }, [bikes]);

  const desireRef = useRef();
  const xFactorRef = useRef();

  return (
    <>
      {/* <Loder /> */}
      <Meta
        title={
          "Electric Bicycle Price in India, Electric Cycle & Battery Cycle Prices"
        }
        description={
          "Find best priced electric bikes with EMotorad's complete electric bike collection. Shop now to purchase electric bicycles at best price!"
        }
        cannonicalTag={"https://www.emotorad.com/bikes"}
      />
      <CallBtn bottom={"20%"} />
      <WhatsAppBot bottom={"12%"} />
      <Navbar />
      <TestRide />

      {/* all bikes desktop main banner */}
      <div className="position-relative d-none d-lg-block">
        <LazyLoadImage
          src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/banners/All+Bike+Banner+(Desktop).jpg"
          className="img-fluid"
        />
        <div className="desire-main-banner-btn-div">
          <a
            href="#desire"
            style={{ fontFamily: "Metropolis-bold", fontSize: "1.1rem" }}
            className="home-cta text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
          >
            EXPLORE NOW
          </a>
        </div>
        <div className="x-factor-main-banner-btn-div">
          <a
            href="#x-factor"
            style={{ fontFamily: "Metropolis-bold", fontSize: "1.1rem" }}
            className="home-cta text-center text-decoration-none p-3 ps-4 pe-4 ps-lg-5 pe-lg-5"
          >
            EXPLORE NOW
          </a>
        </div>
      </div>
      {/* all bikes mobile main banner */}
      <div className="d-block d-lg-none">
        <div className="position-relative">
          <LazyLoadImage
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/banners/Desire.jpg"
            className="img-fluid"
          />
          <button
            onClick={() =>
              desireRef.current.scrollIntoView({ behavior: "smooth" })
            }
            className="home-cta text-center text-decoration-none position-absolute desire-main-banner-btn-div"
            style={{
              padding: "0.8em 2em",
              fontFamily: "Metropolis-bold",
              fontSize: "0.9rem",
            }}
          >
            EXPLORE NOW
          </button>
          <LazyLoadImage
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/banners/Xfactor.jpg"
            className="img-fluid mt-3"
          />
          <button
            onClick={() =>
              xFactorRef.current.scrollIntoView({ behavior: "smooth" })
            }
            style={{
              padding: "0.8em 2em",
              fontFamily: "Metropolis-bold",
              fontSize: "0.9rem",
            }}
            className="home-cta text-center text-decoration-none position-absolute desire x-factor-main-banner-btn-div"
          >
            EXPLORE NOW
          </button>
        </div>
      </div>
      <br></br>
      <h1
        className="mt-4 text-center"
        style={{ fontFamily: "Metropolis-medium" }}
      >
        Electric Bicycle Price Online
      </h1>
      {/* desire range banner */}
      <div id="desire" ref={desireRef}>
        <picture>
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/EMX%2B+mobile.png"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/EMX+%2B+desktop.png"
          />
          <LazyLoadImage
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/EMX+%2B+desktop.png"
            className="img-fluid w-100 rounded-3"
            alt=""
            srcSet=""
          />
        </picture>
      </div>
      <br></br>
      {/* desire range bikes */}
      <div className="ps-lg-3 ps-2 pe-lg-3">
        <Swiper
          navigation={{ clickable: true }}
          spaceBetween={15}
          slidesPerView={1.1}
          initialSlide={2}
          centeredSlides={false}
          loop={true}
          breakpoints={{
            1024: {
              slidesPerView: 3.8,
              spaceBetween: 20,
              centeredSlides: true,
            },
            1724: {
              slidesPerView: 4.3,
              spaceBetween: 20,
              centeredSlides: true,
              loop: true,
              initialSlide: 2,
            },
          }}
          slide
        >
          {bikes &&
            bikes
              .filter(
                (item) =>
                  item.name !== "EMX Aqua" &&
                  item.subCategory === "desire" &&
                  item.name !== "T-Rex+" &&
                  item.name !== "Lil E"
              )
              .map((item, index) => (
                <SwiperSlide key={index} className="p-1">
                  <div
                    className="border position-relative rounded-3 p-2"
                    style={{
                      boxShadow: "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                      overflow: "hidden",
                    }}
                  >
                    <div className="position-relative">
                      <h5 className="text-center fw-bold text-uppercase">
                        {item.subCategory} range
                      </h5>
                      <div className="position-absolute outofstock-alert">
                        {item.inStock === 0 && (
                          <span
                            className="text-end p-1 outofstock-tag"
                            style={{
                              maxWidth: "fit-content",
                              backgroundColor: "#ed2939",
                              color: "#ffffff",
                              fontFamily: "Metropolis-Black",
                            }}
                          >
                            OUT OF STOCK
                          </span>
                        )}
                      </div>
                    </div>

                    <Link
                      to={`/bikes/${item.urlName}`}
                      style={{ color: "inherit" }}
                      className="text-decoration-none"
                    >
                      <div>
                        <img
                          src={item.cartImages[modelDesireStates[index]]}
                          alt=""
                          className="img-fluid "
                        />
                      </div>
                    </Link>
                    <div className="ps-2  pe-2">
                      <div
                        className="d-flex mb-2 justify-content-center"
                        style={{ gap: "0.5em" }}
                      >
                        {item.models.map((model, colorIndex) => (
                          <button
                            key={colorIndex}
                            className={`rounded-circle home-bike-card-color border-0 ${
                              modelDesireStates[index] === colorIndex
                                ? "active"
                                : ""
                            }`}
                            style={{
                              pointer: "cursor",
                              width: "30px",
                              minHeight: "30px",
                              background: model.colorCode,
                            }}
                            onClick={() =>
                              handleDesireColorClick(index, colorIndex)
                            }
                          ></button>
                        ))}
                      </div>
                      <Link
                        to={`/bikes/${item.urlName}`}
                        style={{ color: "inherit" }}
                        className="text-decoration-none"
                      >
                        <div className="mt-4 d-flex justify-content-between">
                          <h3
                            className="w-50"
                            style={{
                              fontSize: "1.5rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight: "600",
                            }}
                          >
                            {item.name}
                          </h3>
                          <div>
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-half"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                          </div>
                        </div>
                        <p
                          style={{
                            fontSize: "0.8em",
                            display: "-webkit-box",
                            maxWidth: "100%",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item.cardDesc}
                        </p>
                        {/* <div
                          className="d-flex align-items-center mt-2 mb-3 justify-content-between"
                          style={{ gap: "1em" }}
                        >
                          <div className="" style={{ gap: "1em" }}>
                            <div className="d-flex justify-content-center mb-2">
                              <img
                                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Frame.png"
                                className="img-fluid mx-auto"
                                style={{ width: "30%" }}
                              ></img>
                            </div>
                            <div className="text-center">
                              <p className="mb-0 fw-bold usp-font-size">
                                {item.name === "T-Rex Air"
                                  ? "High Tensile"
                                  : item.name.startsWith("Doodle")
                                  ? "Foldable"
                                  : "Aluminium 6061"}
                              </p>
                              <p className="mb-0 text-em fw-bold usp-font-size">
                                {item.name === "T-Rex Air"
                                  ? "Steel Frame"
                                  : item.name.startsWith("Doodle")
                                  ? "Aluminium Frame"
                                  : "Alloy Frame"}
                              </p>
                            </div>
                          </div>
                          <div className="" style={{ gap: "1em" }}>
                            <div className="d-flex justify-content-center mb-2">
                              <img
                                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Range.png"
                                className="img-fluid mx-auto"
                                style={{ width: "30%" }}
                              ></img>
                            </div>
                            <div className="text-center">
                              <p className="mb-0 fw-bold usp-font-size">
                                {item.name === "EMX+"
                                  ? "80+ KM"
                                  : item.name === "T-Rex+ V3"
                                  ? "45+ KM"
                                  : item.name === "Doodle V2"
                                  ? "35+ KM"
                                  : item.name === "Doodle V3"
                                  ? "60+ KM"
                                  : "50+ KM"}
                              </p>
                              <p className="mb-0 text-em fw-bold usp-font-size">
                                Range
                              </p>
                            </div>
                          </div>
                          <div className="" style={{ gap: "1em" }}>
                            <div className="d-flex justify-content-center mb-2">
                              <img
                                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Removable+Battery.png"
                                className="img-fluid mx-auto"
                                style={{ width: "30%" }}
                              ></img>
                            </div>
                            <div className="text-center">
                              <p className="mb-0 fw-bold usp-font-size">
                                {item.name === "EMX+"
                                  ? "13AH"
                                  : item.name === "T-Rex Air" ||
                                    item.name === "T-Rex+ V3"
                                  ? "10.2AH"
                                  : item.name === "Doodle V2" ||
                                    item.name === "EMX"
                                  ? "10.4AH"
                                  : "12.75AH"}
                              </p>
                              <p className="mb-0 text-em fw-bold usp-font-size">
                                Battery
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <p style={{ fontSize: "1.4em" }}>
                          Starting at <b>{currencyFormat(item.accPrice)}</b>{" "}
                        </p>
                      </Link>

                      <div className="d-flex mb-3">
                        <div className="w-50 pe-2 testride-btn-allbike">
                          <Link
                            to={"/testride"}
                            className={`btn w-100 border-0 em-btn rounded-3 text-uppercase fw-bold text-decoration-none ${
                              item.inStock === 0 ? "disabled" : ""
                            }`}
                            style={{
                              // color: `${
                              //   item.inStock === 0 ? "black" : "white"
                              // }`,
                              fontSize: "0.8em",
                            }}
                            disabled={item.inStock === 0}
                          >
                            test ride
                          </Link>
                        </div>
                        <div className="w-50 ps-2">
                          <Link
                            to={`/bikes/${item.urlName}`}
                            style={{
                              fontSize: "0.8em",
                            }}
                            className="btn border-0 w-100 know-btn rounded-3 text-uppercase fw-bold"
                          >
                            Know More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <br></br>
      {/* x-factor range banner */}
      <div id="x-factor" ref={xFactorRef}>
        <picture>
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/X1+Mobile.png"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/x1+desktop.png"
          />
          <LazyLoadImage
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/breakers/x1+desktop.png"
            className="img-fluid w-100 rounded-3"
            alt=""
            srcSet=""
          />
        </picture>
      </div>
      <br></br>
      {/* x1 range bikes */}
      <div className="ps-lg-3 ps-2 pe-lg-3">
        <Swiper
          navigation={{ clickable: true }}
          spaceBetween={15}
          slidesPerView={1.1}
          initialSlide={2}
          centeredSlides={false}
          loop={true}
          breakpoints={{
            1024: {
              slidesPerView: 3.8,
              spaceBetween: 20,
              centeredSlides: true,
            },
            1724: {
              slidesPerView: 4.3,
              spaceBetween: 20,
              centeredSlides: true,
              loop: true,
              initialSlide: 2,
            },
          }}
          slide
        >
          {bikes &&
            bikes
              .filter(
                (item) =>
                  item.name !== "EMX" &&
                  item.name !== "Dummy" &&
                  item.name !== "EMX Aqua" &&
                  item.subCategory === "x-factor" &&
                  item.active == true
              )
              .map((item, index) => (
                <SwiperSlide key={index} className="p-1">
                  <div
                    className="border position-relative rounded-3 p-2"
                    style={{
                      boxShadow: "0px 0px 5px #0060D6, -0px -0px 5px #0060D6",
                      overflow: "hidden",
                    }}
                  >
                    <div className="position-relative">
                      <h5 className="text-center fw-bold text-uppercase">
                        {item.subCategory} range
                      </h5>
                      <div className="position-absolute mt-3 outofstock-alert">
                        {item.inStock === 0 && (
                          <span
                            className="text-end p-1 outofstock-tag"
                            style={{
                              maxWidth: "fit-content",
                              backgroundColor: "#ed2939",
                              color: "#ffffff",
                              fontFamily: "Metropolis-Black",
                            }}
                          >
                            OUT OF STOCK
                          </span>
                        )}
                      </div>
                    </div>

                    <div>
                      <Link
                        to={`/bikes/${item.urlName}`}
                        style={{ color: "inherit" }}
                        className="text-decoration-none"
                      >
                        <img
                          src={item.cartImages[modelXStates[index]]}
                          alt=""
                          className="img-fluid "
                        />
                      </Link>
                    </div>
                    <div className="ps-2  pe-2">
                      <div
                        className="d-flex mb-2 justify-content-center"
                        style={{ gap: "0.5em" }}
                      >
                        {item.models.map((model, colorIndex) => (
                          <button
                            key={colorIndex}
                            className={`rounded-circle home-bike-card-color border-0  ${
                              modelXStates[index] === colorIndex ? "active" : ""
                            }`}
                            style={{
                              pointer: "cursor",
                              width: "30px",
                              minHeight: "30px",
                              background: model.colorCode,
                            }}
                            onClick={() => handleXColorClick(index, colorIndex)}
                          ></button>
                        ))}
                      </div>
                      <Link
                        to={`/bikes/${item.urlName}`}
                        style={{ color: "inherit" }}
                        className="text-decoration-none"
                      >
                        <div className="mt-4 d-flex justify-content-between">
                          <h3
                            className=""
                            style={{
                              width: "60%",
                              fontSize: "1.5rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontWeight: "600",
                            }}
                          >
                            {item.name === "X1 Limited Edition" ||
                            item.name === "Legend 07 MSD Edition" ? (
                              <>
                                {item.name === "X1 Limited Edition" ? (
                                  <>
                                    X1{" "}
                                    <span
                                      style={{
                                        fontSize: "0.5em",
                                        fontFamily: "Metropolis-medium",
                                      }}
                                    >
                                      Limited Edition
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    Legend 07{" "}
                                    <span
                                      style={{
                                        fontSize: "0.5em",
                                        fontFamily: "Metropolis-medium",
                                      }}
                                    >
                                      MSD Edition
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              item.name
                            )}
                          </h3>
                          <div>
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-rate"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                            <Icon
                              icon="ic:round-star-half"
                              style={{ color: "#ffce31" }}
                              width={"20"}
                            />
                          </div>
                        </div>

                        <p
                          style={{
                            fontSize: "0.8em",
                            display: "-webkit-box",
                            maxWidth: "100%",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item.cardDesc}
                        </p>

                        {/* <div
                          className="d-flex align-items-center mt-2 mb-3 justify-content-between"
                          style={{ gap: "1em" }}
                        >
                          <div className="" style={{ gap: "1em" }}>
                            <div className="d-flex justify-content-center mb-2">
                              <img
                                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Frame.png"
                                className="img-fluid mx-auto"
                                style={{ width: "30%" }}
                              ></img>
                            </div>
                            <div className="text-center">
                              <p className="mb-0 fw-bold usp-font-size">
                                High-Tensile
                              </p>
                              <p className="mb-0 text-em fw-bold usp-font-size">
                                Steel Frame
                              </p>
                            </div>
                          </div>
                          <div className="" style={{ gap: "1em" }}>
                            <div className="d-flex justify-content-center mb-2">
                              <img
                                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Range.png"
                                className="img-fluid mx-auto"
                                style={{ width: "30%" }}
                              ></img>
                            </div>
                            <div className="text-center">
                              <p className="mb-0 fw-bold usp-font-size">
                                {item.name === "X3" ? "50+ KM" : "40+ KM"}
                              </p>
                              <p className="mb-0 text-em fw-bold usp-font-size">
                                Range
                              </p>
                            </div>
                          </div>
                          <div className="" style={{ gap: "1em" }}>
                            <div className="d-flex justify-content-center mb-2">
                              <img
                                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/icons/Removable+Battery.png"
                                className="img-fluid mx-auto"
                                style={{ width: "30%" }}
                              ></img>
                            </div>
                            <div className="text-center">
                              <p className="mb-0 fw-bold usp-font-size">
                                {item.name === "X3" ? "7AH" : "7.65AH"}
                              </p>
                              <p className="mb-0 text-em fw-bold usp-font-size">
                                Battery
                              </p>
                            </div>
                          </div>
                        </div> */}

                        <p style={{ fontSize: "1.4em" }}>
                          Starting at <b>{currencyFormat(item.accPrice)}</b>
                        </p>
                      </Link>
                      <div className="d-flex mb-3">
                        <div className="w-50 pe-2">
                          <Link
                            to={"/testride"}
                            style={{
                              fontSize: "0.8em",
                            }}
                            className="btn w-100 border-0  em-btn rounded-3  text-uppercase fw-bold text-decoration-none"
                          >
                            test ride
                          </Link>
                        </div>
                        <div className="w-50 ps-2">
                          <Link
                            to={`/bikes/${item.urlName}`}
                            style={{
                              fontSize: "0.8em",
                            }}
                            className="btn border-0 w-100 know-btn rounded-3 text-uppercase fw-bold"
                          >
                            Know More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
      <br></br>
      {/* lifestyle banner */}
      <div id="lifestyle-end" style={{ position: "relative" }}>
        <picture>
          <source
            media="(max-width:650px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/banners/allBikews+mobile.jpg"
          />
          <source
            media="(min-width:651px)"
            srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/banners/allBikes+end.jpg"
          />
          <LazyLoadImage
            src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/allBikesPage/banners/allBikes+end.jpg"
            className="img-fluid w-100 lifestyle-image"
            alt=""
            srcSet=""
          />
        </picture>
      </div>
      <Footer />
    </>
  );
};

export default AllBikesScreen;
