export const productHighlights = [
  {
    name: "beacon",
    category: "emotorad",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      { iconName: "fluent:weather-blowing-snow-48-regular", spec: "20 vents" },
      { iconName: "mdi:helmet", spec: "Adjustable Fit" },
      { iconName: "ph:headlights", spec: "LED Front & Tailight" },
    ],
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/beacon/Beacon_Blue+banner1.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/beacon/Beacon_Blue+banner2.png",
    ],
    faq: [
      {
        question: "How do vents make a difference in the rider experience?",
        answer:
          "Vents keep your head cool by allowing air to flow through the helmet as you ride. This helps prevent overheating and makes your ride much more comfortable, especially on hot days.",
      },
      {
        question: "How often should I replace my helmet?",
        answer:
          "You should replace your helmet after a crash, even if it looks fine. If you haven't had any accidents, it's a good idea to replace it every 2 years to ensure it still offers the best protection.",
      },
      {
        question: "How do I charge my lights?",
        answer:
          "Charging your helmet lights is easy. Simply connect the provided USB cable to the charging port on your helmet and plug it into a power source. The lights will be fully charged in 2-3 hours.",
      },
      {
        question: "Can I wash the inner lining?",
        answer:
          "Yes, you can wash the inner lining! Just remove it from the helmet and hand wash it with mild soap and water. Let it air dry completely before putting it back in.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "miniair",
    category: "emotorad",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "mdi:printer-3d-nozzle",
        spec: "Dual Nozzle",
      },
      { iconName: "lets-icons:pressure-light", spec: "120 PSI Pressure" },
      {
        iconName: "line-md:speedometer-loop",
        spec: "Vibrant Medium-sized Gauge",
      },
    ],
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/miniair/banner1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/miniair/banner2.png",
    ],
    faq: [
      {
        question: "What valves do EMotorad pump support?",
        answer:
          "Most of our pumps support Presta, Schrader, and Dunlop valves for versatile compatibility.",
      },
      {
        question: "How much pressure can the pump handle?",
        answer:
          "Depending on the model, pumps can handle pressures ranging from 100 PSI to 160 PSI. Always check the specific pump's PSI rating for the best performance.",
      },
      {
        question: "How do I know when I’ve reached the right pressure?",
        answer:
          "Many pumps feature a built-in gauge that shows the pressure as you pump. Simply check the gauge to reach your desired PSI. In case there is no gauge, you can manually check the tyre pressure by pressing on it.",
      },
      {
        question: "Can I use EMotorad pump for more than just bikes?",
        answer:
          "Yes, many of our pumps can be used to inflate sports equipment, motorbikes, and more, as long as they are compatible with the valves and pressure requirements.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "blazebeam",
    category: "emotorad",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "ph:headlights-fill",
        spec: "5 light modes",
      },
      { iconName: "uil:brightness", spec: "400 Lumens Brightness" },
      { iconName: "fluent:battery-10-32-regular", spec: "2000mAh Battery" },
    ],
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/blazebeam/banner1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/blazebeam/banner2.png",
    ],
    faq: [
      {
        question: "How do I charge my e-cycle lights?",
        answer:
          "Most e-cycle lights are rechargeable using a USB cable. Simply plug the cable into the charging port on the light and connect it to a power source, like a computer or wall adapter.",
      },
      {
        question: "Can I use my lights in the rain?",
        answer:
          "Yes, most e-cycle lights are water-resistant, meaning they can be used in light to moderate rain. All have IPX5 and IPX6 ratings.",
      },
      {
        question: "Do EMotorad lights have different light settings?",
        answer:
          "Yes, all EMotorad lights come with five light modes such as half bright, full bright, breathing light, slow flash, and fast flash. ",
      },
      {
        question: "Do I need front and rear lights on my e-cycle?",
        answer:
          "Yes, it’s recommended to use both front and rear lights. A front light helps you see ahead, while a rear light makes you visible to others from behind, enhancing safety on the road.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "lumisafe",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      { iconName: "fluent:weather-blowing-snow-48-regular", spec: "18 Vents" },
      { iconName: "mdi:helmet", spec: "Adjustable Fit" },
      { iconName: "icon-park-outline:weight", spec: "Lightweight Design" },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+Red/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+Red/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+Green/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+Green/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+Brown/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+Brown/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+White/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/lumisafe/Black+White/2.jpg",
      ],
    ],
    faq: [
      {
        question: "How do vents make a difference in the rider experience?",
        answer:
          "Vents keep your head cool by allowing air to flow through the helmet as you ride. This helps prevent overheating and makes your ride much more comfortable, especially on hot days.",
      },
      {
        question: "How often should I replace my helmet?",
        answer:
          "You should replace your helmet after a crash, even if it looks fine. If you haven't had any accidents, it's a good idea to replace it every 2 years to ensure it still offers the best protection.",
      },
      {
        question: "How do I charge my lights?",
        answer:
          "Charging your helmet lights is easy. Simply connect the provided USB cable to the charging port on your helmet and plug it into a power source. The lights will be fully charged in 2-3 hours.",
      },
      {
        question: "Can I wash the inner lining?",
        answer:
          "Yes, you can wash the inner lining! Just remove it from the helmet and hand wash it with mild soap and water. Let it air dry completely before putting it back in.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "trailarmour",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      { iconName: "fluent:weather-blowing-snow-48-regular", spec: "13 Vents" },
      { iconName: "mdi:helmet", spec: "Adjustable Fit" },
      { iconName: "icon-park-outline:weight", spec: "Lightweight Design" },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/trailarmour/Black/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/trailarmour/Black/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/trailarmour/Black+Red/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/trailarmour/Black+Red/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/trailarmour/White/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/trailarmour/White/2.jpg",
      ],
    ],
    faq: [
      {
        question: "How do vents make a difference in the rider experience?",
        answer:
          "Vents keep your head cool by allowing air to flow through the helmet as you ride. This helps prevent overheating and makes your ride much more comfortable, especially on hot days.",
      },
      {
        question: "How often should I replace my helmet?",
        answer:
          "You should replace your helmet after a crash, even if it looks fine. If you haven't had any accidents, it's a good idea to replace it every 2 years to ensure it still offers the best protection.",
      },
      {
        question: "How do I charge my lights?",
        answer:
          "Charging your helmet lights is easy. Simply connect the provided USB cable to the charging port on your helmet and plug it into a power source. The lights will be fully charged in 2-3 hours.",
      },
      {
        question: "Can I wash the inner lining?",
        answer:
          "Yes, you can wash the inner lining! Just remove it from the helmet and hand wash it with mild soap and water. Let it air dry completely before putting it back in.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "locksafe",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "marketeq:secure",
        spec: "Secure Key Mechanism",
      },
      {
        iconName: "fluent:weather-partly-cloudy-day-16-regular",
        spec: "All-Weather Protection",
      },
      { iconName: "vaadin:line-h", spec: "Sturdy Iron Cable" },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Lock+Safe/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Lock+Safe/2.jpg",
      ],
    ],
    faq: [
      {
        question: "Is a key or combination lock better for my e-cycle?",
        answer:
          "Both have their benefits. Combination locks offer convenience as you don’t need to carry a key, while key locks may provide slightly more security. It depends on your personal preference and how secure you need your bike to be.",
      },
      {
        question: "Can e-cycle locks be used in all weather conditions?",
        answer:
          "Yes, all our e-cycle locks come with thick PU coating to withstand various weather conditions like rain and heat. However, it’s best to store your lock in a dry place when not in use to prevent rust or damage over time.",
      },
      {
        question: "How often should I replace my e-cycle lock?",
        answer:
          "Replace your lock if it shows signs of wear, like fraying cables or a stiff mechanism. If it’s still in good shape, a lock can last for several years with proper care.",
      },
      {
        question: "How long is the cable on most e-cycle locks?",
        answer:
          "Cable lengths vary, but most Xcape e-cycle locks have 1m long cables. This should be enough to secure your bike to a fixed object like a pole or rack.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "bikeguard",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "marketeq:secure",
        spec: "4-Digit Combination",
      },
      {
        iconName: "fluent:weather-partly-cloudy-day-16-regular",
        spec: "All-Weather Protection",
      },
      { iconName: "vaadin:line-h", spec: "Durable and Flexible Cable" },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Bike+Guard/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Bike+Guard/2.jpg",
      ],
    ],
    faq: [
      {
        question: "Is a key or combination lock better for my e-cycle?",
        answer:
          "Both have their benefits. Combination locks offer convenience as you don’t need to carry a key, while key locks may provide slightly more security. It depends on your personal preference and how secure you need your bike to be.",
      },
      {
        question: "Can e-cycle locks be used in all weather conditions?",
        answer:
          "Yes, all our e-cycle locks come with thick PU coating to withstand various weather conditions like rain and heat. However, it’s best to store your lock in a dry place when not in use to prevent rust or damage over time.",
      },
      {
        question: "How often should I replace my e-cycle lock?",
        answer:
          "Replace your lock if it shows signs of wear, like fraying cables or a stiff mechanism. If it’s still in good shape, a lock can last for several years with proper care.",
      },
      {
        question: "How long is the cable on most e-cycle locks?",
        answer:
          "Cable lengths vary, but most Xcape e-cycle locks have 1m long cables. This should be enough to secure your bike to a fixed object like a pole or rack.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "ultrasafe",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "marketeq:secure",
        spec: "5-Digit Combination",
      },
      {
        iconName: "fluent:weather-partly-cloudy-day-16-regular",
        spec: "All-Weather Protection",
      },
      { iconName: "vaadin:line-h", spec: "Frame Mount" },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Ultra+Safe/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Ultra+Safe/2.jpg",
      ],
    ],
    faq: [
      {
        question: "Is a key or combination lock better for my e-cycle?",
        answer:
          "Both have their benefits. Combination locks offer convenience as you don’t need to carry a key, while key locks may provide slightly more security. It depends on your personal preference and how secure you need your bike to be.",
      },
      {
        question: "Can e-cycle locks be used in all weather conditions?",
        answer:
          "Yes, all our e-cycle locks come with thick PU coating to withstand various weather conditions like rain and heat. However, it’s best to store your lock in a dry place when not in use to prevent rust or damage over time.",
      },
      {
        question: "How often should I replace my e-cycle lock?",
        answer:
          "Replace your lock if it shows signs of wear, like fraying cables or a stiff mechanism. If it’s still in good shape, a lock can last for several years with proper care.",
      },
      {
        question: "How long is the cable on most e-cycle locks?",
        answer:
          "Cable lengths vary, but most Xcape e-cycle locks have 1m long cables. This should be enough to secure your bike to a fixed object like a pole or rack.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "jetflo",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "material-symbols-light:valve-outline-rounded",
        spec: "Triple Valve Compatibility",
      },
      { iconName: "lets-icons:pressure-light", spec: "120 PSI Pressure" },
      {
        iconName: "lucide:grip",
        spec: "Easy Foldable Base",
      },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Jet+Flow/Green/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Jet+Flow/Green/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Jet+Flow/Orange/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Jet+Flow/Orange/2.jpg",
      ],
    ],
    faq: [
      {
        question: "What valves do Xcape pump support?",
        answer:
          "Most of our pumps support Presta, Schrader, and Dunlop valves for versatile compatibility.",
      },
      {
        question: "How much pressure can the pump handle?",
        answer:
          "Depending on the model, pumps can handle pressures ranging from 100 PSI to 160 PSI. Always check the specific pump's PSI rating for the best performance.",
      },
      {
        question: "How do I know when I’ve reached the right pressure?",
        answer:
          "Many pumps feature a built-in gauge that shows the pressure as you pump. Simply check the gauge to reach your desired PSI.",
      },
      {
        question: "Can I use Xcape pump for more than just bikes?",
        answer:
          "Yes, many of our pumps can be used to inflate sports equipment, motorbikes, and more, as long as they are compatible with the valves and pressure requirements.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "freeflo",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "material-symbols-light:valve-outline-rounded",
        spec: "Dual Valve Compatibility",
      },
      { iconName: "lets-icons:pressure-light", spec: "160 PSI Pressure" },
      {
        iconName: "lucide:grip",
        spec: "Triad base",
      },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Free+Flow/Yellow/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Free+Flow/Yellow/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Free+Flow/Red/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Free+Flow/Red/2.jpg",
      ],
    ],
    faq: [
      {
        question: "What valves do Xcape pump support?",
        answer:
          "Most of our pumps support Presta, Schrader, and Dunlop valves for versatile compatibility.",
      },
      {
        question: "How much pressure can the pump handle?",
        answer:
          "Depending on the model, pumps can handle pressures ranging from 100 PSI to 160 PSI. Always check the specific pump's PSI rating for the best performance.",
      },
      {
        question: "How do I know when I’ve reached the right pressure?",
        answer:
          "Many pumps feature a built-in gauge that shows the pressure as you pump. Simply check the gauge to reach your desired PSI.",
      },
      {
        question: "Can I use Xcape pump for more than just bikes?",
        answer:
          "Yes, many of our pumps can be used to inflate sports equipment, motorbikes, and more, as long as they are compatible with the valves and pressure requirements.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "maxflo",
    category: "xcape",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "material-symbols-light:valve-outline-rounded",
        spec: "Triple Valve Compatibility",
      },
      { iconName: "lets-icons:pressure-light", spec: "100 PSI Pressure" },
      {
        iconName: "lucide:grip",
        spec: "Single Foldable Base",
      },
    ],
    lifestyleImages: [
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Max+Flow/Black/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Max+Flow/Black/2.jpg",
      ],
      [
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Max+Flow/Red/1.jpg",
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Max+Flow/Red/2.jpg",
      ],
    ],
    faq: [
      {
        question: "What valves do Xcape pump support?",
        answer:
          "Most of our pumps support Presta, Schrader, and Dunlop valves for versatile compatibility.",
      },
      {
        question: "How much pressure can the pump handle?",
        answer:
          "Depending on the model, pumps can handle pressures ranging from 100 PSI to 160 PSI. Always check the specific pump's PSI rating for the best performance.",
      },
      {
        question: "How do I know when I’ve reached the right pressure?",
        answer:
          "Many pumps feature a built-in gauge that shows the pressure as you pump. Simply check the gauge to reach your desired PSI.",
      },
      {
        question: "Can I use Xcape pump for more than just bikes?",
        answer:
          "Yes, many of our pumps can be used to inflate sports equipment, motorbikes, and more, as long as they are compatible with the valves and pressure requirements.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "foamlite",
    category: "emotorad",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "game-icons:foam",
        spec: "Ortho-Memory Foam",
      },
      { iconName: "line-md:water-off", spec: "Waterproof & Washable" },
      {
        iconName: "logos:fabric",
        spec: "Anti-slip Fabric",
      },
    ],
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Saddle+Seat+Cover/Saddle+Seat+Cover_Bottom+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Saddle+Seat+Cover/Saddle+Seat+Cover_MainBanner.jpg",
    ],
    faq: [
      {
        question: "What makes the Foamlite Saddle Cover comfortable?",
        answer:
          "The Foamlite Saddle Cover is made with ortho-memory-foam, offering superior cushioning and support for a more comfortable ride.",
      },
      {
        question: "Is the saddle cover waterproof?",
        answer:
          "Yes, the Foamlite Saddle Cover is fully waterproof, protecting your seat from rain and moisture while ensuring a dry ride.",
      },
      {
        question: "Will the cover stay securely on my seat?",
        answer:
          "Absolutely! The cover is designed with anti-slip inner fabric, ensuring it stays in place even during long or bumpy rides.",
      },
      {
        question: "Can I wash the Foamlite Saddle Cover?",
        answer:
          "Yes, the saddle cover is washable, making it easy to clean and maintain for long-lasting use.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "metal-fender",
    category: "emotorad",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "codicon:coverage",
        spec: "Full Coverage",
      },
      {
        iconName: "fluent:weather-haze-24-regular",
        spec: "All Weather-Resistant",
      },
      {
        iconName: "material-symbols:done-all",
        spec: "Multi-ebike Compatibility",
      },
    ],
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Fender/banner1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Fender/banner2.jpg",
    ],
    faq: [
      {
        question: "Do the mudguards provide full protection?",
        answer:
          "Yes, these mudguards are designed to offer full coverage, protecting you from dirt, water, and debris during your rides.",
      },
      {
        question:
          "Are the mudguards durable enough for tough weather conditions?",
        answer:
          "Absolutely! Crafted from high-quality metal, these mudguards are built to withstand all types of weather, offering reliable protection in rain, mud, and more.",
      },
      {
        question: "Are the mudguards lightweight despite being made of metal?",
        answer:
          "Yes, despite their sturdy metal construction, these mudguards are designed to be lightweight, ensuring they don't add unnecessary bulk to your bike while still providing excellent protection.",
      },
      {
        question: "Can I install the mudguards myself?",
        answer:
          "Yes, the mudguards are easy to install and come with instructions to help you set them up quickly and securely.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
  {
    name: "carrier",
    category: "emotorad",
    meta: {
      title: "Buy X1 Electric Cycle EMotorad | 16% Off",
      description:
        "Buy the EMotorad X1 Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-yellow-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm37ad9bd2a6754",
    },
    productSpecs: [
      {
        iconName: "mdi:weight-kg",
        spec: "Carries Upto 25kgs",
      },
      {
        iconName: "tabler:universe",
        spec: "Universal Size",
      },
      {
        iconName: "material-symbols:construction",
        spec: "Rugged Steel Construction",
      },
    ],
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Carrier/banner1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/accessoryPage/Carrier/banner2.jpg",
    ],
    faq: [
      {
        question: "What is the maximum weight the carrier can hold?",
        answer:
          "The EMotorad adjustable steel carrier can support up to 25kg of weight, making it perfect for carrying gear on your rides.",
      },
      {
        question: "Is the carrier compatible with EMotorad models?",
        answer:
          "The carrier is designed to be compatible with various EMotorad models like the X1, X2, Trex Air, ST-X, Neo, and Legend 07. Always check the specific model to ensure proper fit.",
      },
      {
        question: "Can the carrier handle rough terrain?",
        answer:
          "Built from premium steel, the carrier is durable and sturdy enough to handle rough terrain and challenging rides while securely holding your items.",
      },
    ],
    reviews: {
      product: ["4.8", "700"],
    },
  },
];
